import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { selectOrderTotalValue, selectSubtotalValue, selectTotalQuantity } from '../../cart/useCart.js';
import BusinessLogo from '../../components/BusinessLogo.jsx';
import { OrderDiscountTypes } from '../../constants/OrderDiscountTypes.jsx';
import { OrderStatuses } from '../../constants/OrderStatuses.jsx';
import { OrderTermsTypes } from '../../constants/OrderTermsTypes.jsx';
import CancelOrderDialog from '../retail/orders/CancelOrderDialog';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Stack from '@mui/material/Stack';
import { Teal, Tide } from '../../theme.js';
import { useEffect, useState } from 'react';
import { Collapse, IconButton } from '@mui/material';
import CaretCircleDownIcon from '../../icons/CaretCircleDownIcon.jsx';
import CaretCircleUpIcon from '../../icons/CaretCircleUpIcon.jsx';
import PoweredByThatch from '../../components/PoweredByThatch.jsx';

const states = {
    BRAND_REVIEW: 'BRAND_REVIEW',
    BRAND_SALES_REP: 'BRAND_SALES_REP',
    CART: 'CART',
    CHECKOUT: 'CHECKOUT',
    ORDER: 'ORDER',
    SUCCESS: 'SUCCESS',
};

export default function OrderSummaryCard(props) {
    const { business, order, products = [], variant = states.CART, state = {}, isCollapsible = false } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const flags = useFlags();
    const [showCancelOrderDialogue, setShowCancelOrderDialogue] = React.useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);

    let discountAmount = 0;
    let discountType = OrderDiscountTypes.DOLLARS;
    let shippingCost = 0;
    let status;
    let subtotal = products?.reduce((acc, curr) => acc + curr.wholesale * curr.quantity, 0);
    let orderTotal = subtotal + shippingCost - discountAmount;
    let terms = OrderTermsTypes.PAY_UP_FRONT;
    let totalQuantity = products?.reduce((acc, curr) => acc + curr.quantity, 0);
    let paymentTerms = null;
    let storeCredit = 0;

    if (variant === states.BRAND_SALES_REP) {
        discountAmount = state.discountAmount;
        discountType = state.discountType;
        orderTotal = selectOrderTotalValue(state);
        paymentTerms = state.paymentTerms || { id: 0, title: t('OrderSummaryCard.dueNowLabel') };
        shippingCost = state.shippingCost;
        subtotal = selectSubtotalValue(state);
        totalQuantity = selectTotalQuantity(state);
        storeCredit = state.storeCredit || 0;
        /** deprecated */
        terms = state.terms || OrderTermsTypes.PAY_UP_FRONT;
    }

    if ((variant === states.ORDER || states.BRAND_REVIEW) && order) {
        discountAmount = order.discountAmount;
        discountType = order.discountType;
        orderTotal = order.total;
        shippingCost = order.shippingCost;
        status = order.status;
        subtotal = order.subTotal || selectSubtotalValue(order);
        const paymentTermOptions = {
            0: t('OrderSummaryCard.dueNowLabel'),
            [-1]: t('OrderSummaryCard.payOnScanLabel'),
            [-2]: t('OrderSummaryCard.consignmentLabel'),
        };
        terms =
            order.paymentTermData?.title ??
            paymentTermOptions[order.paymentTermId] ??
            t('OrderSummaryCard.dueNowLabel');
        totalQuantity = order?.orderItems
            ? order.orderItems?.reduce((acc, curr) => acc + parseInt(curr.quantity), 0)
            : selectTotalQuantity(order);
        storeCredit = order.storeCredit || 0;
    }

    const handleCancelOrder = () => {
        setShowCancelOrderDialogue(true);
    };

    const handleViewAllOrdersClick = () => {
        navigate('/retail/orders');
    };

    const handleViewInvoice = () => {
        // TODO
    };

    const handleCollapseToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

    useEffect(() => {
        setIsCollapsed(isCollapsible);
    }, [isCollapsible]);

    const discountValue = () => {
        if (discountType === OrderDiscountTypes.PERCENT) {
            return Number(subtotal) * (Number(discountAmount) / 100);
        } else {
            return Number(discountAmount);
        }
    };

    return (
        <Card
            sx={{
                textAlign: 'center',
                width: { sm: 326 },
                ...props?.sx,
            }}>
            <Stack gap={1} direction="row" sx={{ alignItems: 'center', justifyContent: 'flex-start', my: 3, mx: 3 }}>
                {props.hideLogo ? null : (
                    <Box
                        sx={{
                            borderRadius: '24px',
                            height: '48px',
                            width: '48px',
                            minWidth: '48px',
                            minHeight: '48px',
                            overflow: 'hidden',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <BusinessLogo business={business} sx={{ height: '48px', width: '48px' }} />
                    </Box>
                )}
                <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Stack>
                        {props.hideTitle ? null : (
                            <Typography variant="label" sx={{ fontSize: 10, pb: 1 }}>
                                {t('OrderSummaryCard.title')}
                            </Typography>
                        )}
                        {props.hideBusinessName ? null : <Typography variant="displayXs">{business?.name}</Typography>}
                    </Stack>
                    {isCollapsible ? (
                        <IconButton onClick={handleCollapseToggle} sx={{ padding: 0 }}>
                            {isCollapsed ? <CaretCircleUpIcon /> : <CaretCircleDownIcon />}
                        </IconButton>
                    ) : null}
                </Stack>
            </Stack>
            <Collapse in={!isCollapsed}>
                <Divider sx={{ mb: 2, mx: 2 }} />
                <CardContent>
                    <Stack gap={1} sx={{ width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="textXs" sx={{ fontWeight: 600, color: Teal }}>
                                {t('OrderSummaryCard.totalQuantityLabel')}
                            </Typography>
                            <Typography variant="textXs" sx={{ fontWeight: 600 }}>
                                {totalQuantity}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="textXs" sx={{ fontWeight: 600, color: Teal }}>
                                {t('OrderSummaryCard.subtotalLabel')}
                            </Typography>
                            <Typography variant="textXs" sx={{ fontWeight: 600 }}>
                                <NumericFormat
                                    prefix="$"
                                    displayType="text"
                                    value={subtotal}
                                    thousandSeparator
                                    decimalScale={2}
                                    fixedDecimalScale
                                />
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="textXs" sx={{ fontWeight: 600, color: Teal }}>
                                {t('OrderSummaryCard.shippingLabel')}
                            </Typography>
                            <Typography variant="textXs" sx={{ fontWeight: 600 }}>
                                {props?.ShippingComponent ||
                                    (!shippingCost ? null : (
                                        <NumericFormat
                                            prefix="$"
                                            displayType="text"
                                            value={shippingCost}
                                            thousandSeparator
                                            decimalScale={2}
                                            fixedDecimalScale
                                        />
                                    )) ||
                                    '—'}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="textXs" sx={{ fontWeight: 600, color: Teal }}>
                                {t('OrderSummaryCard.discountLabel')}
                            </Typography>
                            <Typography variant="textXs" sx={{ fontWeight: 600 }}>
                                {props?.DiscountComponent ||
                                    (!discountAmount ? null : (
                                        <NumericFormat
                                            prefix={'$'}
                                            displayType="text"
                                            value={discountValue()}
                                            thousandSeparator
                                            decimalScale={2}
                                            fixedDecimalScale
                                        />
                                    )) ||
                                    '—'}
                            </Typography>
                        </Box>
                        {flags?.storeCreditFeature && storeCredit > 0 ? (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="textXs" sx={{ fontWeight: 600, color: Teal }}>
                                    {t('OrderSummaryCard.storeCredit')}
                                </Typography>
                                <Typography variant="textXs" sx={{ fontWeight: 600 }}>
                                    -{' '}
                                    <NumericFormat
                                        prefix="$"
                                        displayType="text"
                                        value={storeCredit}
                                        thousandSeparator
                                        decimalScale={2}
                                        fixedDecimalScale
                                    />
                                </Typography>
                            </Box>
                        ) : null}
                        {flags?.paymentTerms ? (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="textXs" sx={{ fontWeight: 600, color: Teal }}>
                                    {t('OrderSummaryCard.termsLabel')}
                                </Typography>
                                <Typography variant="textXs" sx={{ fontWeight: 600 }}>
                                    {paymentTerms?.title || terms}
                                </Typography>
                            </Box>
                        ) : null}
                    </Stack>

                    <Divider sx={{ my: 2 }} />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: 3,
                            color: 'common.blueTypography',
                        }}>
                        <Typography variant="textSm" sx={{ color: Tide, fontWeight: 700 }}>
                            {t('OrderSummaryCard.orderTotalLabel')}
                        </Typography>
                        <Typography variant="textSm" sx={{ color: Tide, fontWeight: 700 }}>
                            <NumericFormat
                                prefix="$"
                                displayType="text"
                                value={orderTotal}
                                thousandSeparator
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </Typography>
                    </Box>
                    {!props?.OrderSummaryCardActions ? null : props.OrderSummaryCardActions}
                    <Box sx={{ '>div': { my: 1 } }}>
                        {variant === states.ORDER && status === OrderStatuses.IN_REVIEW && (
                            <>
                                <Box>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        onClick={handleCancelOrder}>
                                        {t('OrderSummaryCard.buttons.cancelOrderLabel')}
                                    </Button>
                                </Box>
                                <CancelOrderDialog
                                    orderId={order?.id}
                                    open={showCancelOrderDialogue}
                                    onClose={() => setShowCancelOrderDialogue(false)}
                                    onSuccess={() => navigate('.', { replace: true })}
                                    businessId={business?.businessId}
                                />
                                <Box>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        onClick={handleViewAllOrdersClick}>
                                        {t('OrderSummaryCard.buttons.viewAllOrdersLabel')}
                                    </Button>
                                </Box>
                            </>
                        )}
                        {variant === states.SUCCESS ? (
                            <Box>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    onClick={handleViewInvoice}>
                                    {t('OrderSummaryCard.buttons.viewInvoiceLabel')}
                                </Button>
                            </Box>
                        ) : null}
                        {variant === states.ORDER && status === OrderStatuses.CANCELLED && (
                            <Box>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={handleViewAllOrdersClick}>
                                    {t('OrderSummaryCard.buttons.viewAllOrdersLabel')}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </CardContent>
                <Box sx={{ pb: 4, mt: 1 }}>
                    <PoweredByThatch />
                </Box>
            </Collapse>
        </Card>
    );
}

OrderSummaryCard.propTypes = {
    OrderSummaryCardActions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    business: PropTypes.any,
    order: PropTypes.any,
    products: PropTypes.any,
    DiscountComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    ShippingComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    variant: PropTypes.oneOf(Object.values(states)),
    state: PropTypes.any,
    sx: PropTypes.any,
    hideTitle: PropTypes.bool,
    hideLogo: PropTypes.bool,
    hideBusinessName: PropTypes.bool,
    isCollapsible: PropTypes.bool,
};

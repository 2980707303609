import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { Alert, Fog, Success, White } from '../../theme.js';
import { inviteStatuses } from '../DealerInvites/inviteUtils.js';

export default function InviteStatusChip({ invite }) {
    const color = (() => {
        switch (invite.status) {
            case inviteStatuses.invited:
                return Fog;
            case inviteStatuses.accepted:
                return Success;
            case inviteStatuses.bounced:
                return Alert;
            default:
                return White;
        }
    })();

    return (
        <Chip variant="orderStatus" label={invite.status} sx={{ minWidth: 100, backgroundColor: color, height: 28 }} />
    );
}

InviteStatusChip.propTypes = {
    invite: PropTypes.object.isRequired,
};

import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Outlet, useLoaderData } from 'react-router-dom';
import * as api from '../../api.js';
import * as businessProvider from '../../providers/businessProvider.js';
import * as userProvider from '../../providers/userProvider.js';
import SettingsSidebar from '../../components/SettingsSidebar/SettingSidebar.jsx';
import { SettingsProvider } from './SettingsContext.jsx';
import AccountMenu from '../../components/AccountMenu/AccountMenu.jsx';
import { BusinessTypes } from '../../constants/BusinessTypes.jsx';

const drawerWidth = 240;

export async function loader({ request }) {
    const { signal } = request;
    const rootUser = await userProvider.getUser({ signal });
    const [user, business] = await Promise.all([
        api.getUserById({ id: rootUser.user.id, signal }),
        businessProvider.getBusiness({ signal }),
    ]);

    return {
        business,
        user,
    };
}

export default function Settings({ type }) {
    const data = useLoaderData();
    const { business = {} } = data || {};
    const { user = {} } = data || {};
    return (
        <SettingsProvider initialBusiness={business} initialUser={user}>
            <Box
                sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, position: 'relative' }}
                data-testid="settings-layout">
                <SettingsSidebar type={type} user={user} business={business} />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        bgcolor: 'background.default',
                        p: 3,
                        width: { xs: `calc(100% - ${drawerWidth}px)` },
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            height: { xs: 48, sm: 'unset' },
                            mb: { xs: 2, sm: 'unset' },
                        }}>
                        <Box>{business?.type === BusinessTypes.RETAIL || <AccountMenu />}</Box>
                    </Box>
                    <Outlet />
                </Box>
            </Box>
        </SettingsProvider>
    );
}

Settings.propTypes = {
    type: PropTypes.string,
};

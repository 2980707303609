import * as React from 'react';
import { Box, Stack, TextField, IconButton } from '@mui/material';
import TrashIcon from '../../../icons/TrashIcon.jsx';
import { Misty, SoftBG } from '../../../theme.js';
import PropTypes from 'prop-types';

export default function PaymentTermInput({
    isDeletable,
    onChange,
    onTrashClick,
    paymentTerm,
    textFieldProps,
    ...rest
}) {
    const [netDays, setNetDays] = React.useState(paymentTerm.netDays);
    const [title, setTitle] = React.useState(paymentTerm.title);

    const displayedValue =
        paymentTerm.type === 'NET_DAYS' && !paymentTerm.isSystemPaymentTerm ? `Net ${netDays}` : title;

    const handleChange = (event) => {
        if (onChange) {
            onChange(event);
        }
        if (paymentTerm.type === 'NET_DAYS') {
            setNetDays(event.target.value);
        } else {
            setTitle(event.target.value);
        }
    };

    return (
        <Stack direction="row" gap={2} alignItems="center" {...rest}>
            <TextField
                sx={{ '.MuiInputBase-root.Mui-disabled': { backgroundColor: SoftBG } }}
                size="small"
                required
                fullWidth
                value={displayedValue}
                onChange={handleChange}
                name={paymentTerm.id.toString() + '_' + (paymentTerm.type === 'NET_DAYS' ? 'netDays' : 'title')}
                id={`payment-term-${paymentTerm.id}`}
                {...textFieldProps}
            />
            {isDeletable ? (
                <IconButton
                    onClick={() => {
                        onTrashClick();
                    }}>
                    <TrashIcon sx={{ color: Misty, width: 24, height: 24 }} />
                </IconButton>
            ) : (
                <Box className="placeholder" sx={{ width: 40, height: 40 }} />
            )}
        </Stack>
    );
}

PaymentTermInput.propTypes = {
    disabled: PropTypes.bool,
    isEditable: PropTypes.bool,
    isDeletable: PropTypes.bool,
    onChange: PropTypes.func,
    onTrashClick: PropTypes.func,
    paymentTerm: PropTypes.object,
    textFieldProps: PropTypes.object,
};

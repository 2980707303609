import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormInputLabel from '../FormInputLabel/FormInputLabel.jsx';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PencilSimpleIcon from '../../icons/PencilSimpleIcon.jsx';
import { Midnight, Teal } from '../../theme.js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigation, useSubmit } from 'react-router-dom';
import PricingGroupSelect from '../PricingGroupSelect/PricingGroupSelect.jsx';
import _ from 'lodash';
import { CircularProgress } from '@mui/material';
import PaymentTermsSelect from '../PaymentTermsSelect/PaymentTermsSelect.jsx';

export default function DealerSettingsSidebar({
    connectionSettings,
    availableStatuses,
    onSaveNote,
    onStatusChange,
    onPaymentTermsChange,
    children,
}) {
    const { pricingGroups } = useLoaderData() || {};
    const [currentStatus, setCurrentStatus] = useState(connectionSettings.status ?? '');
    const [customerType] = useState('Retailer');
    const [paymentTerms, setPaymentTerms] = useState(connectionSettings.paymentTermId ?? 0);
    const [isEditingNote, setIsEditingNote] = useState(false);
    const [note, setNote] = useState(connectionSettings.note ?? 'Testing Notes');
    const flags = useFlags();
    const { t } = useTranslation();
    const [selectedPricingGroup, setSelectedPricingGroup] = useState(connectionSettings?.pricingGroup?.id ?? '');
    const submit = useSubmit();
    const navigation = useNavigation();
    const isLoading = navigation.state === 'submitting' || navigation.state === 'loading';
    const isSavingPricingGroup = isLoading && navigation.json?.intent === 'updatePricingGroup';

    const handleStatusChange = (event) => {
        onStatusChange(event.target.value);
        setCurrentStatus(event.target.value);
    };

    const handlePaymentTermsChange = (value) => {
        onPaymentTermsChange(value);
        setPaymentTerms(value);
    };

    const saveNote = () => {
        onSaveNote(note);
        setIsEditingNote(false);
    };

    const cancelNoteEdit = () => {
        setIsEditingNote(false);
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const handlePricingGroupChange = (group) => {
        setSelectedPricingGroup(group.id);
        let pricingGroupId = null;
        let pricingGroup = null;
        if (!_.isEmpty(group)) {
            pricingGroupId = group.id;
            pricingGroup = group;
        }
        submit(
            {
                intent: 'updatePricingGroup',
                id: connectionSettings.retailBusinessId,
                settings: { ...connectionSettings, pricingGroupId, pricingGroup },
            },
            { method: 'put', encType: 'application/json' }
        );
    };

    return (
        <Stack gap={2} sx={{ minWidth: '300px', maxWidth: 200 }}>
            <Card>
                <CardContent x={{ padding: '24px' }}>
                    <FormInputLabel text={t('Dealers.statusLabel')} sx={{ fontSize: 12 }}>
                        <Select value={currentStatus} onChange={handleStatusChange} sx={{ width: '100%', height: 40 }}>
                            {availableStatuses.map((i) => (
                                <MenuItem key={i} value={i}>
                                    <Typography variant="textSm" sx={{ fontWeight: 500, pt: 0.3 }}>
                                        {capitalize(i)}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormInputLabel>
                </CardContent>
            </Card>
            <Card>
                <CardContent x={{ padding: '24px' }}>
                    <Stack gap={2}>
                        <FormInputLabel text={t('Dealers.customerTypelabel')} sx={{ fontSize: 12 }}>
                            <Select value={customerType} disabled sx={{ width: '100%', height: 40 }}>
                                <MenuItem value={customerType}>
                                    <Typography variant="textSm" sx={{ fontWeight: 500, pt: 0.3 }}>
                                        Retailer
                                    </Typography>
                                </MenuItem>
                            </Select>
                        </FormInputLabel>
                        <PaymentTermsSelect
                            isLoading={
                                navigation.state === 'submitting' && navigation.json?.intent === 'updatePaymentTerms'
                            }
                            label={t('Dealers.paymentTermsLabel')}
                            value={paymentTerms}
                            onChange={handlePaymentTermsChange}
                        />
                        {flags.pricingGroups ? (
                            <FormInputLabel
                                text={t('Dealers.pricingGroupLabel')}
                                sx={{ fontSize: 12 }}
                                adornment={isSavingPricingGroup ? <CircularProgress size={15} /> : null}>
                                <PricingGroupSelect
                                    groups={pricingGroups}
                                    defaultValue={selectedPricingGroup}
                                    onChange={handlePricingGroupChange}
                                />
                            </FormInputLabel>
                        ) : null}
                    </Stack>
                </CardContent>
            </Card>
            {children}
            <Card>
                <CardContent x={{ padding: '24px' }}>
                    <Stack gap={1}>
                        <FormInputLabel
                            text={t('Dealers.noteLabel')}
                            sx={{ fontSize: 12 }}
                            adornment={
                                !isEditingNote ? (
                                    <IconButton onClick={() => setIsEditingNote(true)} sx={{ padding: 0 }}>
                                        <PencilSimpleIcon sx={{ width: 18.75, height: 18.75, color: Teal }} />
                                    </IconButton>
                                ) : null
                            }>
                            {isEditingNote ? (
                                <TextField
                                    multiline
                                    rows={5}
                                    fullWidth
                                    value={note}
                                    onChange={(event) => setNote(event.target.value)}
                                    InputProps={{
                                        style: { fontSize: 14, fontWeight: 500, color: Midnight },
                                    }}
                                />
                            ) : (
                                <Typography variant="textSm" sx={{ fontWeight: 500, color: Midnight }}>
                                    {note}
                                </Typography>
                            )}
                        </FormInputLabel>
                        {isEditingNote ? (
                            <Stack gap={1} direction="row">
                                <Button
                                    onClick={cancelNoteEdit}
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ height: 32 }}>
                                    Cancel
                                </Button>
                                <Button onClick={saveNote} variant="contained" sx={{ height: 32 }}>
                                    Save
                                </Button>
                            </Stack>
                        ) : null}
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    );
}

DealerSettingsSidebar.propTypes = {
    connectionSettings: PropTypes.object.isRequired,
    availableStatuses: PropTypes.array.isRequired,
    onSaveNote: PropTypes.func,
    onStatusChange: PropTypes.func,
    onPaymentTermsChange: PropTypes.func,
    children: PropTypes.node,
};

import React from 'react';
import PDFGenerator from '../PDFGenerator/PDFGenerator.jsx';
import OrderInvoice from '../OrderInvoice/OrderInvoice.jsx';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { formattedPriceString } from '../../routes/brand/Products/ProductVariants/ProductVariantTable/productUtil.js';
import { formatDate } from '../../routes/retail/orders/OrderDetailsHeader.jsx';
import { dueDateForOrder } from '../PaymentDueDate/paymentTermsUtils.js';
import { LightGray } from '../../theme.js';
import PoweredByThatch from '../PoweredByThatch.jsx';

export default function OrderInvoiceButton({ order, brand, sx }) {
    const dueDate = () => {
        return dueDateForOrder(order);
    };

    const footer = () => {
        return (
            <Stack
                direction="row"
                sx={{
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    position: 'relative',
                }}>
                <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
                    <Typography sx={{ fontSize: 14, fontWeight: 700 }}>#{order.id}</Typography>
                    <Typography sx={{ fontSize: 11, fontWeight: 500 }}>
                        - {formattedPriceString(order.total)}
                        {dueDate() && ` due ${formatDate(dueDate())}`}
                    </Typography>
                </Stack>
                <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                    <PoweredByThatch color={LightGray} />
                </Box>
            </Stack>
        );
    };

    return (
        <PDFGenerator
            filename={`Order_${order.id}_Invoice`}
            text="View Invoice"
            variant="outlined"
            color="secondary"
            sx={sx}
            footer={footer()}>
            <OrderInvoice order={order} brand={brand} />
        </PDFGenerator>
    );
}

OrderInvoiceButton.propTypes = {
    order: PropTypes.object.isRequired,
    brand: PropTypes.object.isRequired,
    sx: PropTypes.object,
};

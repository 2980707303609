import LoadingButton from '../LoadingButton/LoadingButton.jsx';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function InviteActionButtons({ invite, onResend, onOrderReminder, isLoadingResend, isLoadingReminder }) {
    const { t } = useTranslation();
    const handleOrderReminder = () => {
        onOrderReminder(invite);
    };
    const handleResend = () => {
        onResend(invite);
    };

    return (
        <Box>
            {!isEmpty(invite.status) ? (
                invite.status === 'Invited' || invite.status === 'Bounced' ? (
                    <LoadingButton
                        isLoading={isLoadingResend}
                        onClick={handleResend}
                        variant="outlined"
                        color="secondary"
                        sx={{ height: 32, fontSize: 12, width: 155 }}>
                        {t('InviteActionButtons.resendInviteLabel')}
                    </LoadingButton>
                ) : invite.orderPlaced ? null : (
                    <LoadingButton
                        isLoading={isLoadingReminder}
                        onClick={handleOrderReminder}
                        variant="outlined"
                        color="secondary"
                        sx={{ height: 32, fontSize: 12, width: 155 }}>
                        {t('InviteActionButtons.sendOrderReminderLabel')}
                    </LoadingButton>
                )
            ) : null}
        </Box>
    );
}

InviteActionButtons.propTypes = {
    invite: PropTypes.object.isRequired,
    onResend: PropTypes.func,
    onOrderReminder: PropTypes.func,
    isLoadingReminder: PropTypes.bool,
    isLoadingResend: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography, Box } from '@mui/material';
import { Teal, Midnight70 } from '../../theme.js';
import Divider from '@mui/material/Divider';
import { formattedPriceString } from '../../routes/brand/Products/ProductVariants/ProductVariantTable/productUtil.js';
import { useTranslation } from 'react-i18next';

export default function OrderTable({ order }) {
    const { t } = useTranslation();
    const headerStyle = {
        fontWeight: 'bold',
        fontFamily: 'Inter',
        textTransform: 'uppercase',
        fontSize: 8,
        letterSpacing: 1,
        color: Midnight70,
    };

    const cellStyle = {
        fontFamily: 'Inter',
        fontSize: 12,
        wordBreak: 'break-word',
    };

    return (
        <Stack>
            {/* Table Header */}
            <Stack gap={2} direction="row" sx={{ paddingBottom: 1 }}>
                <Box flex={3}>
                    <Typography sx={headerStyle}>{t('OrderInvoice.descriptionLabel')}</Typography>
                </Box>
                <Box flex={1} textAlign="center">
                    <Typography sx={headerStyle}>{t('OrderInvoice.quantityLabel')}</Typography>
                </Box>
                <Box flex={1} textAlign="center">
                    <Typography sx={headerStyle}>{t('OrderInvoice.unitPriceLabel')}</Typography>
                </Box>
                <Box flex={1} textAlign="right">
                    <Typography sx={headerStyle}>{t('OrderInvoice.amountLabel')}</Typography>
                </Box>
            </Stack>

            <Divider />

            {/* Table Rows */}
            <Stack>
                {order.orderItems.map((orderItem, index) => {
                    const variantTags = orderItem.productVariant.variantTags
                        .map((tag) => `${tag.name} ${tag.value}`)
                        .join(', ');

                    return (
                        <Stack key={index}>
                            <Stack direction="row" spacing={2} sx={{ alignItems: 'center', py: 2 }}>
                                <Box flex={3}>
                                    <Typography sx={{ ...cellStyle, fontWeight: 700 }}>
                                        {orderItem.productVariant.product.title}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            ...cellStyle,
                                            fontSize: 10,
                                            color: Teal,
                                        }}>
                                        {variantTags}
                                    </Typography>
                                </Box>
                                <Box flex={1} textAlign="center">
                                    <Typography sx={{ ...cellStyle, fontWeight: 700 }}>{orderItem.quantity}</Typography>
                                </Box>
                                <Box flex={1} textAlign="center">
                                    <Typography sx={{ ...cellStyle, fontWeight: 700 }}>
                                        {formattedPriceString(`${Number(orderItem.price).toFixed(2)}`)}
                                    </Typography>
                                </Box>
                                <Box flex={1} textAlign="right">
                                    <Typography sx={{ ...cellStyle, fontWeight: 700 }}>
                                        {formattedPriceString(
                                            `${(Number(orderItem.quantity) * Number(orderItem.price)).toFixed(2)}`
                                        )}
                                    </Typography>
                                </Box>
                            </Stack>
                            <Divider />
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    );
}

OrderTable.propTypes = {
    order: PropTypes.object.isRequired,
};

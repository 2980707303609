import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

export default function OrderSaveChanges({ onDismiss }) {
    const { t } = useTranslation();
    const handleDismiss = () => {
        onDismiss();
    };

    return (
        <Stack gap={3}>
            <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                {t('OrderSaveChanges.title')}
            </Typography>
            <Typography variant="textMd" sx={{ fontWeight: 500 }}>
                {t('OrderSaveChanges.message')}
            </Typography>
            <Button onClick={handleDismiss} variant="contained" sx={{ width: 150 }}>
                {t('OrderSaveChanges.okButton')}
            </Button>
        </Stack>
    );
}

OrderSaveChanges.propTypes = {
    onDismiss: PropTypes.func,
};

/**
 * Enums representing the Thatch supported business types
 */
export const BusinessTypes = {
    BRAND: 'Brand',
    RETAIL: 'Retail',
};

export const mapBusinessType = (type) => {
    if (!type) return null;
    return BusinessTypes[type.toUpperCase()];
};

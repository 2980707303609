import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function StoreCreditsIcon(props) {
    return (
        <SvgIcon component="svg" sx={props?.sx} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="AddStoreCredit">
                <path
                    id="Vector"
                    d="M13.5862 12.0001L8.29325 17.2931C7.90225 17.6841 7.90225 18.3171 8.29325 18.7071C8.68425 19.0971 9.31725 19.0981 9.70725 18.7071L15.7072 12.7071C16.0982 12.3161 16.0982 11.6831 15.7072 11.2931L9.70725 5.29312C9.31625 4.90212 8.68325 4.90212 8.29325 5.29312C7.90325 5.68412 7.90225 6.31713 8.29325 6.70713L13.5862 12.0001Z"
                    fill="#5C7B7D"
                />
            </g>
        </SvgIcon>
    );
}

StoreCreditsIcon.propTypes = {
    sx: PropTypes.any,
};

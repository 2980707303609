import { OrderDiscountTypes } from '../../../constants/OrderDiscountTypes.jsx';

export function calculatePricing(order, updatedInfo = {}) {
    const {
        discountAmount = order.discountAmount,
        discountType = order.discountType,
        shippingCost = order.shippingCost,
        storeCredit = order.storeCredit || 0,
    } = updatedInfo;

    // Calculate subtotal
    const subTotal = order.orderItems.reduce((acc, curr) => acc + Number(curr.quantity) * Number(curr.price), 0);

    // Calculate discount
    let discountTotal = 0;
    if (Number(discountAmount) > 0 && discountType) {
        discountTotal =
            discountType === OrderDiscountTypes.PERCENT
                ? Math.round(subTotal * Number(discountAmount) * 100) / 10000
                : Math.round(Number(discountAmount) * 100) / 100;
    }

    const shipping = shippingCost !== undefined ? Math.round(Number(shippingCost) * 100) / 100 : 0;

    let storeCreditTotal = storeCredit;
    if (storeCredit >= subTotal) {
        storeCreditTotal = subTotal;
    }

    const total = Math.round((subTotal - discountTotal - storeCreditTotal + shipping) * 100) / 100;

    return {
        subTotal: Math.round(subTotal * 100) / 100,
        discountTotal,
        shipping,
        storeCredit: storeCreditTotal,
        total,
    };
}

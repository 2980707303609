import { addDays } from 'date-fns';
export function dueDateForOrder(order) {
    const netDays = order.paymentTermData?.netDays || 0;

    if (!order.shippedOn) {
        return null;
    }

    const shippedOnDate = new Date(order.shippedOn);
    if (isNaN(shippedOnDate.getTime())) {
        return null;
    }

    const dueDate = addDays(shippedOnDate, netDays);
    return new Date(dueDate);
}

import { Dialog } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetcher, useLoaderData } from 'react-router-dom';
import * as api from '../../../api.js';
import CreatePaymentTerms from '../../../components/CreatePaymentTerms/CreatePaymentTerms.jsx';
import Crumbs from '../../../components/Crumbs/Crumbs.jsx';
import PricingGroupList from '../../../components/PricingGroupsList/PricingGroupsList.jsx';
import TagIcon from '../../../icons/TagIcon.jsx';
import UserCircleGearIcon from '../../../icons/UserCircleGearIcon.jsx';
import * as businessProvider from '../../../providers/businessProvider.js';
import { Teal } from '../../../theme.js';
import PaymentTerms from '../PaymentTerms/PaymentTerms.jsx';
import { sortPricingGroups } from '../../../components/PricingGroupsList/pricingGroupUtils.js';
import { useFlags } from 'launchdarkly-react-client-sdk';

export async function loader({ request }) {
    const response = await api.getPricingGroups({ signal: request.signal });

    // Sort so that pricingGroup with is_system_generated = true comes first
    const sortedPricingGroups = sortPricingGroups(response.data);

    return {
        pricingGroups: sortedPricingGroups,
    };
}

export async function action({ request }) {
    try {
        const body = await request.json();
        const { intent, newGroups, editedGroups, deletedGroupIds, deleteVariantGroups } = body;
        const business = await businessProvider.getBusiness();

        if (intent === 'savePricingGroups') {
            const promises = [];

            // Create new pricing groups
            if (!_.isEmpty(newGroups)) {
                const createGroupPromises = newGroups.map((group) =>
                    api.createPricingGroup({ ...group, businessId: business.businessId }, { signal: request.signal })
                );
                promises.push(...createGroupPromises);
            }

            // Update existing pricing groups
            if (!_.isEmpty(editedGroups)) {
                const updateGroupPromises = editedGroups.map((group) =>
                    api.updatePricingGroup(
                        group.id,
                        { ...group, businessId: business.businessId, deleteVariantGroups },
                        { signal: request.signal }
                    )
                );
                promises.push(...updateGroupPromises);
            }
            // Delete specified pricing groups
            if (!_.isEmpty(deletedGroupIds)) {
                const deleteGroupPromises = api.deletePricingGroups(deletedGroupIds, { signal: request.signal });
                promises.push(deleteGroupPromises);
            }
            const response = await Promise.all(promises);
            return response;
        }
    } catch (error) {
        return { error };
    }
    return null;
}

export default function DealerSettings() {
    const { t } = useTranslation();
    const [openNewPaymentTerm, setOpenNewPaymentTerm] = useState(false);
    const { pricingGroups } = useLoaderData();
    const fetcher = useFetcher();
    const flags = useFlags();

    React.useEffect(() => {
        if (flags.paymentTerms && fetcher.state === 'idle' && !fetcher.data) {
            fetcher.load('/api/payment-terms?pageSize=50');
        }
    }, [flags, fetcher]);

    const defaultPaymentTerms = fetcher.data?.defaultPaymentTerms?.data?.data;
    const customPaymentTerms = fetcher.data?.customPaymentTerms?.data?.data;

    return (
        <Stack gap={3} sx={{ maxWidth: '600px' }}>
            <Crumbs />
            <Stack gap={1}>
                <Stack direction="row" gap={2}>
                    <UserCircleGearIcon sx={{ width: 32, height: 32, color: Teal }} />
                    <Typography variant="displaySm" fontWeight={700}>
                        {t('DealerSettings.title')}
                    </Typography>
                </Stack>
                <Typography variant="textMd" sx={{ fontWeight: 500 }}>
                    {t('DealerSettings.subTitle')}
                </Typography>
            </Stack>
            <PaymentTerms defaultPaymentTerms={defaultPaymentTerms} customPaymentTerms={customPaymentTerms} />
            <Card>
                <CardContent sx={{ padding: '24px' }}>
                    <Stack gap={3}>
                        <Stack gap={2} direction="row" sx={{ alignItems: 'center' }}>
                            <TagIcon sx={{ width: 24, height: 24, color: Teal }} />
                            <Typography variant="textLg" fontWeight={700}>
                                {t('DealerSettings.pricingGroupsLabel')}
                            </Typography>
                        </Stack>
                        <Typography variant="textSm" fontWeight={500}>
                            {t('DealerSettings.pricingGroupsDescription')}
                        </Typography>
                        <PricingGroupList groups={pricingGroups} />
                    </Stack>
                </CardContent>
            </Card>
            <Dialog open={openNewPaymentTerm} onClose={() => setOpenNewPaymentTerm(false)}>
                <CreatePaymentTerms />
            </Dialog>
        </Stack>
    );
}

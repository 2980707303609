import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import { Midnight, SoftBG, White } from '../../theme.js';
import CaretDownIcon from '../../icons/CaretDownIcon.jsx';
import CaretUpIcon from '../../icons/CaretUpIcon.jsx';

function ArrowUpwardIcon(props) {
    return null;
}

ArrowUpwardIcon.propTypes = { fontSize: PropTypes.string };

function ArrowDownwardIcon(props) {
    return null;
}

ArrowDownwardIcon.propTypes = { fontSize: PropTypes.string };
export default function ThatchGridPro({
    columns,
    rows,
    pageSize = 5,
    rowsPerPageOptions = [5],
    onRowClick,
    height = '100%',
    width = '100%',
    getRowClassName,
    columnHeaderBackground = Midnight,
    cellPadding = '16px',
    autoHeight = false,
    disableRowSelectionOnClick = false,
    onRowsScrollEnd,
    sortingMode = 'client',
    sortModel = [],
    onSortModelChange,
    disableMultipleColumnsSorting = false,
}) {
    return (
        <DataGridPro
            columns={columns}
            rows={rows}
            pageSize={pageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowClick={onRowClick}
            getRowClassName={getRowClassName}
            autoHeight={autoHeight}
            getRowHeight={(params) => 'auto'}
            hideFooter
            disableColumnReorder
            disableColumnMenu
            disableColumnSorting
            disableRowSelectionOnClick={disableRowSelectionOnClick}
            onRowsScrollEnd={onRowsScrollEnd}
            sortingMode={sortingMode}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            disableMultipleColumnsSorting={disableMultipleColumnsSorting}
            slots={{
                columnSortedAscendingIcon: CaretUpIcon,
                columnSortedDescendingIcon: CaretDownIcon,
            }}
            sx={{
                width,
                height,
                borderRadius: '16px 16px 0px 0px',
                overflow: 'hidden',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                border: 'none',
                '& .MuiDataGrid-cell': {
                    padding: cellPadding,
                    borderBottom: 'none',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                },
                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: columnHeaderBackground,
                    color: White,
                    fontSize: '12px',
                    fontFamily: 'Inter',
                    fontWeight: 700,
                    letterSpacing: 2,
                },
                '& .MuiDataGrid-sortIcon': {
                    color: White,
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: '600',
                    padding: '0 16px',
                },
                '& .MuiDataGrid-root': {
                    borderRadius: '16px 16px 0px 0px',
                },
                '& .MuiDataGrid-row': {
                    '&:nth-of-type(even)': {
                        backgroundColor: SoftBG,
                    },
                    '&:nth-of-type(odd)': {
                        backgroundColor: White,
                    },
                    cursor: 'pointer',
                },
                '& .MuiDataGrid-footerContainer': {
                    backgroundColor: White,
                },
                '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                },
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                    outlineOffset: '0',
                    outline: 'none !important',
                },
            }}
        />
    );
}

ThatchGridPro.propTypes = {
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    pageSize: PropTypes.number,
    rowsPerPageOptions: PropTypes.array,
    onRowClick: PropTypes.func,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    getRowClassName: PropTypes.func,
    columnHeaderBackground: PropTypes.string,
    cellPadding: PropTypes.string,
    autoHeight: PropTypes.bool,
    disableRowSelectionOnClick: PropTypes.bool,
    onRowsScrollEnd: PropTypes.func,
    sortingMode: PropTypes.string,
    sortModel: PropTypes.any,
    onSortModelChange: PropTypes.func,
    disableMultipleColumnsSorting: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DueDate from '../DueDate/DueDate.jsx';
import AlarmIcon from '../../icons/AlarmIcon.jsx';
import { addDays } from 'date-fns';
import { PaymentStatuses } from '../../constants/PaymentStatuses.jsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
const DUE_NOW_ID = 0;

const PaymentDueDate = ({ order }) => {
    const { t } = useTranslation();
    const flags = useFlags();
    const paymentTermId = order?.paymentTermId ?? DUE_NOW_ID;
    const completedPayment = order?.payments?.find((p) => p.status === PaymentStatuses.COMPLETE);
    if (!order || !order?.shippedOn || paymentTermId < 0 || completedPayment || order?.cancelledOn) {
        // consignment and pay on scan have ids < 0
        return null;
    }
    const netDays = order.paymentTermData?.netDays || 0;
    const dueDate = addDays(new Date(order.shippedOn), netDays);

    if (!flags?.paymentTerms) {
        return null;
    }
    return (
        <DueDate
            icon={<AlarmIcon sx={{ width: '24px', height: '24px' }} />}
            prefix={t('PaymentDueDate.paymentLabel')}
            date={new Date(dueDate)}
        />
    );
};

export default PaymentDueDate;

PaymentDueDate.propTypes = {
    order: PropTypes.object.isRequired,
};

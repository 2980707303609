import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { Teal, Tide, White } from '../../../theme.js';
import PropTypes from 'prop-types';

export default function SettingItem({ icon: Icon, label, isSelected, onClick }) {
    return (
        <ListItemButton
            onClick={onClick}
            sx={{
                padding: 1,
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                borderRadius: '8px',
                backgroundColor: isSelected ? Tide : 'transparent',
                '&:hover': {
                    backgroundColor: isSelected ? Tide : 'transparent',
                },
            }}>
            <Icon
                sx={{
                    color: isSelected ? White : Teal,
                    width: 32,
                    height: 32,
                }}
            />
            <Typography variant="textMd" sx={{ color: White, fontWeight: 700 }}>
                {label}
            </Typography>
        </ListItemButton>
    );
}

SettingItem.propTypes = {
    icon: PropTypes.elementType.isRequired,
    label: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

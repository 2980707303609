import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ArrowElbowDownRight(props) {
    return (
        <SvgIcon component="svg" sx={props?.sx} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="ArrowElbowDownRight">
                <path
                    id="Vector"
                    d="M20.7806 17.0306L16.2806 21.5306C16.1399 21.6714 15.949 21.7504 15.75 21.7504C15.551 21.7504 15.3601 21.6714 15.2194 21.5306C15.0786 21.3899 14.9996 21.199 14.9996 21C14.9996 20.801 15.0786 20.6101 15.2194 20.4694L18.4397 17.25H6.75C6.55109 17.25 6.36032 17.171 6.21967 17.0303C6.07902 16.8897 6 16.6989 6 16.5V3C6 2.80109 6.07902 2.61032 6.21967 2.46967C6.36032 2.32902 6.55109 2.25 6.75 2.25C6.94891 2.25 7.13968 2.32902 7.28033 2.46967C7.42098 2.61032 7.5 2.80109 7.5 3V15.75H18.4397L15.2194 12.5306C15.0786 12.3899 14.9996 12.199 14.9996 12C14.9996 11.801 15.0786 11.6101 15.2194 11.4694C15.3601 11.3286 15.551 11.2496 15.75 11.2496C15.949 11.2496 16.1399 11.3286 16.2806 11.4694L20.7806 15.9694C20.8504 16.039 20.9057 16.1217 20.9434 16.2128C20.9812 16.3038 21.0006 16.4014 21.0006 16.5C21.0006 16.5986 20.9812 16.6962 20.9434 16.7872C20.9057 16.8783 20.8504 16.961 20.7806 17.0306Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}

ArrowElbowDownRight.propTypes = {
    sx: PropTypes.any,
};

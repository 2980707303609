import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Typography,
} from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useFetcher, useRouteLoaderData } from 'react-router-dom';
import * as api from '../../../api.js';
import LoadingButton from '../../../components/LoadingButton/LoadingButton.jsx';
import PlusCircleIcon from '../../../icons/PlusCircleIcon.jsx';
import SealPercentIcon from '../../../icons/SealPercentIcon.jsx';
import CreatePaymentTermsForm from './CreatePaymentTermsForm.jsx';
import PaymentTermInput from './PaymentTermInput.jsx';

export async function loader({ request }) {
    const url = new URL(request.url);
    const pageSize = url.searchParams.get('pageSize');
    const [defaultPaymentTerms, customPaymentTerms] = await Promise.all([
        api.getDefaultPaymentTerms({ signal: request.signal }),
        api.getPaymentTerms({ query: pageSize ? { pageSize } : null, signal: request.signal }),
    ]);
    customPaymentTerms?.data?.data?.sort((a, b) => a.id - b.id);
    return { defaultPaymentTerms, customPaymentTerms };
}

/**
 * Handle PaymentTerm Actions
 * @param {Object} actionPayload
 * @returns
 */
export async function action({ request }) {
    try {
        const formData = await request.formData();
        const intent = formData.get('intent');
        const businessId = formData.get('businessId');
        // Create Payment Term
        if (request.method === 'POST' && intent === 'createPaymentTerm') {
            const type = formData.get('type');
            const netDays = formData.get('netDays');
            const title = formData.get('title') || (type === 'NET_DAYS' ? `Net ${netDays}` : '');
            const discountPercent = formData.get('discountPercent');
            const discountDays = formData.get('discountDays');
            const payload = { businessId, title, type, netDays, discountPercent, discountDays };
            const data = await api.createPaymentTerm(payload, { signal: request.signal });
            return { status: 'success', data };
        }
        // Update Payment Term
        if (request.method === 'PUT' && intent === 'updatePaymentTerms') {
            const entries = formData.entries();
            const payload = [];
            for (const [key, value] of entries) {
                if (key.includes('_')) {
                    const [paymentTermId, field] = key.split('_');
                    const title = value;
                    let v = value;
                    if (field === 'netDays') {
                        v = Number(value.replace('Net ', ''));
                    }
                    payload.push({ businessId, id: Number(paymentTermId), title, [field]: v });
                }
            }
            const data = await api.bulkUpdatePaymentTerms(payload, { signal: request.signal });
            return { status: 'success', data };
        }
        // Delete Payment Term
        if (request.method === 'DELETE' && intent === 'deletePaymentTerm') {
            const paymentTermId = formData.get('paymentTermId');
            const data = await api.deletePaymentTerm(paymentTermId);
            return { status: 'success', data };
        }
        return { status: 'error', error: 'Unhandled intent' };
    } catch (error) {
        return { status: 'error', error };
    }
}

export default function PaymentTerms(props) {
    const { t } = useTranslation();
    const fetcher = useFetcher();
    const flags = useFlags();
    const [showAddCustomPaymentTermsForm, setShowAddCustomPaymentTermsForm] = React.useState(false);
    const [showDeletePaymentTermDialog, setShowDeletePaymentTermDialog] = React.useState(false);
    const [selectedPaymentTerm, setSelectedPaymentTerm] = React.useState(null);
    const rootData = useRouteLoaderData('root');
    const businessId = rootData?.business?.businessId;
    const isSubmitting = fetcher.state === 'submitting';
    const { defaultPaymentTerms = [], customPaymentTerms = [] } = props;

    const handleTrashClick = (paymentTerm) => {
        setSelectedPaymentTerm(paymentTerm);
        setShowDeletePaymentTermDialog(true);
    };
    const handleDeletePaymentTerm = () => {
        const paymentTermId = selectedPaymentTerm.id;
        const formData = new FormData();
        formData.append('intent', 'deletePaymentTerm');
        formData.append('paymentTermId', paymentTermId);
        fetcher.submit(formData, {
            action: '/brand/settings/payment-terms',
            method: 'delete',
        });
    };

    React.useEffect(() => {
        if (fetcher.state === 'idle' && fetcher.data?.status === 'success') {
            setShowDeletePaymentTermDialog(false);
        }
    }, [fetcher]);

    return (
        <>
            {flags.paymentTerms ? (
                <Card>
                    <CardContent sx={{ padding: '24px' }}>
                        <Stack gap={2}>
                            <Stack gap={1} direction="row" sx={{ alignItems: 'center' }}>
                                <SealPercentIcon sx={{ width: 24, height: 24 }} />
                                <Typography variant="textLg" fontWeight={700}>
                                    {t('PaymentTerms.paymentTermsLabel')}
                                </Typography>
                            </Stack>
                            <Typography variant="textSm" fontWeight={500}>
                                {t('PaymentTerms.paymentTermsDescription')}
                            </Typography>
                            <Typography variant="textSm" fontWeight={700}>
                                {t('PaymentTerms.defaultPaymentTermsLabel')}
                            </Typography>
                            <Typography variant="label" fontSize={12} fontWeight={800}>
                                {t('PaymentTerms.termsLabel')}
                            </Typography>
                            {defaultPaymentTerms.map((paymentTerm) => (
                                <PaymentTermInput
                                    key={paymentTerm.id}
                                    textFieldProps={{ disabled: true }}
                                    isDeletable={!paymentTerm.isSystemPaymentTerm}
                                    paymentTerm={paymentTerm}
                                    onTrashClick={() => handleTrashClick(paymentTerm)}
                                />
                            ))}
                            <Divider />
                            {customPaymentTerms.length > 0 ? (
                                <Stack gap={2}>
                                    <Typography variant="textSm" fontWeight={700}>
                                        {t('PaymentTerms.customPaymentTermsLabel')}
                                    </Typography>
                                    <Typography variant="label" fontSize={12} fontWeight={800}>
                                        {t('PaymentTerms.termsLabel')}
                                    </Typography>
                                    {customPaymentTerms.map((paymentTerm) => (
                                        <PaymentTermInput
                                            key={paymentTerm.id}
                                            isDeletable={true}
                                            textFieldProps={{ inputProps: { readOnly: true } }}
                                            paymentTerm={paymentTerm}
                                            onTrashClick={() => handleTrashClick(paymentTerm)}
                                        />
                                    ))}
                                    <Divider />
                                </Stack>
                            ) : null}
                            <Stack gap={2}>
                                <Typography variant="textSm" fontWeight={700}>
                                    {t('PaymentTerms.newCustomPaymentTermLabel')}
                                </Typography>
                                {showAddCustomPaymentTermsForm ? (
                                    <CreatePaymentTermsForm
                                        action="/brand/settings/payment-terms"
                                        businessId={businessId}
                                        id="create-payment-terms-form"
                                        onSubmit={() => setShowAddCustomPaymentTermsForm(false)}
                                        onTrashClick={() => setShowAddCustomPaymentTermsForm(false)}
                                    />
                                ) : (
                                    <Stack
                                        direction="row"
                                        sx={{ alignItem: 'center', justifyContent: 'space-between' }}>
                                        <Button
                                            onClick={() => setShowAddCustomPaymentTermsForm(true)}
                                            color="secondary"
                                            variant="outlined"
                                            sx={{ paddingX: '16px', height: 32 }}>
                                            <Stack
                                                gap={1}
                                                direction="row"
                                                sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                                <PlusCircleIcon sx={{ width: 16, height: 16 }} />
                                                <Typography
                                                    noWrap
                                                    variant="textMd"
                                                    sx={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        fontSize: 12,
                                                    }}>
                                                    {t('PaymentTerms.addAnotherOption')}
                                                </Typography>
                                            </Stack>
                                        </Button>
                                    </Stack>
                                )}
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            ) : null}
            {/* Dialog for confirming deletion of a payment term */}
            <Dialog open={showDeletePaymentTermDialog} onClose={() => setShowDeletePaymentTermDialog(false)}>
                <DialogTitle>{t('PaymentTerms.deleteDialogTitle')}</DialogTitle>
                <DialogContent>{t('PaymentTerms.deleteDialogContent')}</DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => setShowDeletePaymentTermDialog(false)}>
                        {t('PaymentTerms.deleteDialogCancel')}
                    </Button>
                    <LoadingButton
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleDeletePaymentTerm}
                        isLoading={isSubmitting}>
                        {t('PaymentTerms.deleteDialogDelete')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

PaymentTerms.propTypes = {
    defaultPaymentTerms: PropTypes.array,
    customPaymentTerms: PropTypes.array,
};

import { Box, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFetcher } from 'react-router-dom';
import ButtonWithMenu from '../../../components/ButtonWithMenu/ButtonWithMenu';
import FormInputLabel from '../../../components/FormInputLabel/FormInputLabel';
import LoadingButton from '../../../components/LoadingButton/LoadingButton';
import TrashIcon from '../../../icons/TrashIcon';
import { Misty } from '../../../theme';
import NumberInput from '../../../components/NumberInput/NumberInput';

export default function CreatePaymentTermsForm({ action, businessId, id, onSubmit, onTrashClick }) {
    const { t } = useTranslation();
    const flags = useFlags();
    const fetcher = useFetcher();
    const isSubmitting = fetcher.state === 'submitting';
    const earlyPaymentDiscountEnabled = flags.earlyPaymentDiscountEnabled;
    let paymentTermsTypes = ['NET_DAYS', 'EARLY_PAYMENT_DISCOUNT', 'CUSTOM'];
    if (!earlyPaymentDiscountEnabled) {
        paymentTermsTypes = paymentTermsTypes.filter((type) => type !== 'EARLY_PAYMENT_DISCOUNT');
    }
    const [paymentTermType, setPaymentTermType] = React.useState('');
    const [netDays, setNetDays] = React.useState(1);
    const [discountPercent, setDiscountPercent] = React.useState('');
    const [discountDays, setDiscountDays] = React.useState('');
    const [customPaymentTerms, setCustomPaymentTerms] = React.useState('');

    const handleNetDaysChange = (e) => setNetDays(e.target.value);
    const handleDiscountPercentChange = (e) => setDiscountPercent(e.target.value);
    const handleDiscountDaysChange = (e) => setDiscountDays(e.target.value);
    const handleCustomPaymentTermsChange = (e) => setCustomPaymentTerms(e.target.value);

    React.useEffect(() => {
        if (fetcher.state === 'idle' && fetcher.data?.status === 'success') {
            setPaymentTermType('');
            setNetDays('');
            setDiscountPercent('');
            setDiscountDays('');
            setCustomPaymentTerms('');
            onSubmit();
        }
    }, [fetcher]);

    return (
        // For create action handling see PaymentTerms.jsx
        <fetcher.Form id={id} action={action} method="post">
            <input type="hidden" name="intent" value="createPaymentTerm" />
            <input type="hidden" name="businessId" value={businessId} />
            <input type="hidden" name="type" value={paymentTermType} />
            <Box sx={{ mb: 2 }}>
                <ButtonWithMenu
                    options={paymentTermsTypes.map((type) => ({
                        value: type,
                        label: t(`PaymentTerms.${type}`),
                    }))}
                    value={paymentTermType}
                    onChange={(value) => {
                        setPaymentTermType(value);
                        setNetDays('');
                        setDiscountPercent('');
                        setDiscountDays('');
                        setCustomPaymentTerms('');
                    }}
                    placeholder={t('PaymentTerms.selectTermTypeLabel')}
                />
            </Box>
            <Stack>
                {paymentTermType === 'NET_DAYS' ? (
                    <Stack direction="row" gap={2} sx={{ mb: 2 }}>
                        <FormInputLabel sx={{ fontSize: 12 }} text={t('PaymentTerms.netDaysLabel')}>
                            <NumberInput
                                decimalScale={0}
                                min={1}
                                max={999}
                                name="netDays"
                                size="small"
                                required
                                fullWidth
                                value={netDays}
                                onChange={handleNetDaysChange}
                            />
                        </FormInputLabel>
                        <Stack justifyContent="flex-end">
                            <IconButton
                                onClick={() => {
                                    onTrashClick();
                                }}>
                                <TrashIcon sx={{ color: Misty, width: 24, height: 24 }} />
                            </IconButton>
                        </Stack>
                    </Stack>
                ) : null}
                {paymentTermType === 'EARLY_PAYMENT_DISCOUNT' ? (
                    <Stack direction="row" gap={1} sx={{ mb: 2 }}>
                        <FormInputLabel sx={{ fontSize: 12 }} text={t('PaymentTerms.discountLabel')}>
                            <TextField
                                name="discountPercent"
                                size="small"
                                required
                                fullWidth
                                value={discountPercent}
                                onChange={handleDiscountPercentChange}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                        </FormInputLabel>
                        <FormInputLabel sx={{ fontSize: 12 }} text={t('PaymentTerms.discountDaysLabel')}>
                            <TextField
                                name="discountDays"
                                size="small"
                                required
                                fullWidth
                                value={discountDays}
                                onChange={handleDiscountDaysChange}
                            />
                        </FormInputLabel>
                        <FormInputLabel sx={{ fontSize: 12 }} text={t('PaymentTerms.netDaysLabel')}>
                            <TextField
                                name="netDays"
                                size="small"
                                required
                                fullWidth
                                value={netDays}
                                onChange={handleNetDaysChange}
                            />
                        </FormInputLabel>
                        <Stack justifyContent="flex-end">
                            <IconButton
                                onClick={() => {
                                    onTrashClick();
                                }}>
                                <TrashIcon sx={{ color: Misty, width: 24, height: 24 }} />
                            </IconButton>
                        </Stack>
                    </Stack>
                ) : null}
                {paymentTermType === 'CUSTOM' ? (
                    <Stack direction="row" gap={1} sx={{ mb: 2 }}>
                        <input type="hidden" name="netDays" value="0" />
                        <FormInputLabel sx={{ fontSize: 12 }} text={t('PaymentTerms.customPaymentTermsLabel')}>
                            <TextField
                                name="title"
                                size="small"
                                required
                                fullWidth
                                value={customPaymentTerms}
                                onChange={handleCustomPaymentTermsChange}
                            />
                        </FormInputLabel>
                        <Stack justifyContent="flex-end">
                            <IconButton
                                onClick={() => {
                                    onTrashClick();
                                }}>
                                <TrashIcon sx={{ color: Misty, width: 24, height: 24 }} />
                            </IconButton>
                        </Stack>
                    </Stack>
                ) : null}
            </Stack>
            <Stack direction="row" justifyContent="flex-end">
                <LoadingButton
                    disabled={!(paymentTermType && (customPaymentTerms || netDays))}
                    type="submit"
                    variant="contained"
                    sx={{ height: 40 }}
                    isLoading={isSubmitting}>
                    {t('PaymentTerms.saveButton')}
                </LoadingButton>
            </Stack>
        </fetcher.Form>
    );
}

CreatePaymentTermsForm.propTypes = {
    action: PropTypes.string,
    businessId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onTrashClick: PropTypes.func.isRequired,
};

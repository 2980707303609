import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect, useMemo, useState } from 'react';
import { useFetcher, useNavigate, useNavigation, useRouteLoaderData, useSearchParams } from 'react-router-dom';
import * as api from '../../../../api';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import { EmptyTable } from '../../../../components/EmptyTable/EmptyTable.jsx';
import ProductIcon from '../../../../components/ProductIcon.jsx';
import shopifyIcon from '../../../../assets/shopify-logo.svg';
import Stack from '@mui/material/Stack';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as businessProvider from '../../../../providers/businessProvider.js';
import _, { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../../components/PageHeader/PageHeader.jsx';
import Typography from '@mui/material/Typography';
import { getWholesaleRangeString } from '../../../../components/PricingGroupsList/pricingGroupUtils.js';
import ThatchGridPro from '../../../../components/ThatchGridPro/ThatchGridPro.jsx';
import PlusCircleIcon from '../../../../icons/PlusCircleIcon.jsx';
import QuickActionButton from '../../../../components/QuickActionButton/QuickActionButton.jsx';
import isEmpty from 'lodash/isEmpty.js';
import { NumericFormat } from 'react-number-format';
import { useMediaQuery } from '@mui/material';
import theme from '../../../../theme.js';

const LIMIT = 10;

export async function productsApiLoader({ request }) {
    const url = new URL(request.url);
    const offset = url.searchParams.get('offset') || 0;
    const sort = url.searchParams.get('sort') || 'title';
    const searchString = url.searchParams.get('searchString') || '';
    const active = url.searchParams.get('active') || '';

    const params = {
        query: {
            limit: LIMIT,
            offset,
            sort,
        },
    };

    if (searchString) {
        params.query.searchString = searchString;
    }

    if (active) {
        params.query.active = active;
    }

    const products = await api.getProducts(params);
    return { products };
}

export async function loader({ request }) {
    const url = new URL(request.url);
    const offset = url.searchParams.get('offset') || 0;
    const sort = url.searchParams.get('sort');
    const order = url.searchParams.get('order');
    const searchString = url.searchParams.get('searchString') || '';
    const active = url.searchParams.get('active') || '';

    const params = {
        query: {
            limit: LIMIT,
            offset,
        },
    };

    if (order) {
        params.query.order = order;
    }

    if (sort) {
        params.query.sort = sort;
    }

    if (searchString) {
        params.query.searchString = searchString;
    }

    if (active) {
        params.query.active = active;
    }

    const products = await api.getProducts(params);
    const pricingGroups = await api.getPricingGroups({ signal: request.signal });
    const sortedPricingGroups =
        pricingGroups?.data.sort((a, b) => {
            return b.is_system_generated - a.is_system_generated;
        }) ?? [];
    const { signal } = request;
    const business = await businessProvider.getBusiness({ signal });
    const categories = await api.getCategoriesForBusiness(business?.id, { signal: request.signal });
    return {
        business,
        categories,
        products,
        pricingGroups: sortedPricingGroups,
    };
}

export const setQuery = ({ active, searchString, sort }, func) => {
    let params = {};
    if (_.isString(searchString)) {
        if (_.isEmpty(searchString)) {
            params = {};
        } else {
            params = { searchString };
        }
    }
    if (_.isString(sort) && !_.isEmpty(sort) && sort !== 'title') {
        params.sort = sort;
    }
    params.active = _.toString(active);
    func(params);
};

export default function BrandProductTable() {
    const data = useRouteLoaderData('brand-products');
    const { business, pricingGroups, categories } = data;
    const linkedShops = business?.shopifySessions;

    const navigate = useNavigate();
    const { state } = useNavigation();
    const isLoading = state === 'loading';
    const [products, setProducts] = useState(data?.products?.rows);
    const [hasMore, setHasMore] = useState(data?.products?.hasMore);
    const [offset, setOffset] = useState(data?.products?.offset || 0);
    const [search, setParams] = useSearchParams();
    const activeDefault = search.get('active') === 'true' || search.get('active') === null;
    const [activeFilter, setActiveFilter] = useState(activeDefault);
    const [searchValue, setSearchValue] = useState(search.get('searchString'));
    const sort = search.get('sort') || 'title';
    const flags = useFlags();
    const fetcher = useFetcher();
    const { t } = useTranslation();
    const [sortModel, setSortModel] = useState([]);
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));

    const queryProducts = useMemo(function () {
        return debounce(setQuery, 400);
    }, []);

    const handleSearchInputChange = (event) => {
        const value = event.target.value;
        setSearchValue(value);
        setOffset(0);
        queryProducts({ active: activeFilter, searchString: value, sort }, setParams);
    };

    const handleShowArchived = () => {
        const value = !activeFilter;
        setActiveFilter(value);
        setOffset(0);
        queryProducts({ active: value, searchString: searchValue, sort }, setParams);
    };

    useEffect(() => {
        if (data?.products?.rows) {
            setProducts(data.products.rows);
            setHasMore(data.products.hasMore);
        }
    }, [data]);

    useEffect(() => {
        if (!fetcher.data || fetcher.state !== 'idle') {
            return;
        }
        const { products } = fetcher.data;
        if (products) {
            setHasMore(products.hasMore);
            setProducts((prev) => [...prev, ...products.rows]);
        }
    }, [fetcher]);

    const loadMoreRows = () => {
        if (!hasMore) {
            return;
        }
        const newOffset = offset + LIMIT;
        setOffset(newOffset);

        const queryString = new URLSearchParams();
        queryString.set('offset', newOffset);
        if (activeFilter) {
            queryString.set('active', activeFilter);
        }
        if (searchValue) {
            queryString.set('searchString', searchValue);
        }
        if (sort) {
            queryString.set('sort', sort);
        }
        const query = `/api/brand/products/?${queryString.toString()}`;
        fetcher.load(query);
    };

    const handleOnRowsScrollEnd = () => {
        loadMoreRows();
    };

    const categoryNameForCategoryIds = (categoryIds) => {
        let lastId = null;
        for (let i = categoryIds.length - 1; i >= 0; i--) {
            if (categoryIds[i] !== null) {
                lastId = categoryIds[i];
                break;
            }
        }
        const categoryName = categories.find((category) => String(category.id) === String(lastId))?.name ?? '';
        return categoryName;
    };

    const columns = [
        {
            field: 'title',
            headerName: t('BrandProductTable.productNameHeader'),
            flex: 1,
            minWidth: 175,
            renderCell: (params) => (
                <Stack gap={3} direction="row" sx={{ alignItems: 'center' }}>
                    <ProductIcon
                        src={params.row.imageLinks?.[0]}
                        objectFit="cover"
                        sx={{ width: '45px', height: '45px' }}
                    />
                    <Typography>{params.value === 0 ? '' : params.value}</Typography>
                </Stack>
            ),
        },
        {
            field: 'msrp',
            headerName: t('BrandProductTable.msrpColumnHeader'),
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                const value = isEmpty(params.value) ? 0 : params.value;
                return (
                    <NumericFormat
                        value={value}
                        displayType={'text'}
                        thousandSeparator
                        decimalScale={2}
                        valueIsNumericString
                        prefix="$"
                        fixedDecimalScale={true}
                    />
                );
            },
        },
        {
            field: 'wholesale',
            headerName: t('BrandProductTable.wholesaleColumnHeader'),
            width: 250,
            align: 'center',
            flex: 1,
            headerAlign: 'center',
            sortable: false,
            renderCell: (params) => <Typography>{getWholesaleRangeString(params.row, pricingGroups)}</Typography>,
        },
        {
            field: 'productVariants',
            headerName: t('BrandProductTable.variantsColumnHeader'),
            width: 150,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            renderCell: (params) => <Typography>{params.value.length ?? 0}</Typography>,
        },
        {
            field: 'categories',
            headerName: t('BrandProductTable.categoryColumnHeader'),
            minWidthWidth: 175,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            renderCell: (params) => <Typography>{categoryNameForCategoryIds(params.value)}</Typography>,
        },
    ];

    const filteredColumns = columns.filter((column) => {
        if (isXs && (column.field === 'productVariants' || column.field === 'msrp' || column.field === 'categories')) {
            return false;
        }
        return true;
    });

    const navigateToCreateProductPage = () => {
        navigate('/brand/products/create-product');
    };

    const importShopify = () => {
        navigate('/brand/products/syncShopify');
    };

    const handleCellClick = (params, event) => {
        if (params.field === 'Variants') {
            // Stop the click event from propagating to the row click handler
            event.stopPropagation();
            // Navigate to the variants page for the clicked product
            navigate(`/brand/products/${params.id}/variants`);
        }
    };

    const handleRowClick = (params) => {
        navigate(`/brand/products/${params.id}`);
    };

    const shouldShowShopifySync = () => {
        return flags.shopify && !_.isEmpty(linkedShops);
    };

    const handleSortModelChange = (model) => {
        setSortModel(model);
    };

    const updateSortParams = () => {
        setParams((prev) => {
            const update = { ...Object.fromEntries(prev) };
            if (isEmpty(sortModel)) {
                delete update.sort;
                delete update.order;
                return update;
            } else {
                sortModel.forEach((model) => {
                    if (model.field && model.sort) {
                        update.sort = model.field;
                        update.order = model.sort;
                    }
                });
                return update;
            }
        });
    };

    useEffect(() => {
        updateSortParams();
    }, [sortModel]);
    const addProductButton = () => {
        return (
            <Button
                onClick={navigateToCreateProductPage}
                key={'add-product'}
                color="secondary"
                variant="outlined"
                sx={{
                    paddingX: '16px',
                    height: 44,
                    width: 'auto',
                }}>
                <Stack gap={1} direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <PlusCircleIcon sx={{ width: 24, height: 24 }} />
                    <Typography
                        noWrap
                        variant="textSm"
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {t('BrandProductTable.addProductLabel')}
                    </Typography>
                </Stack>
            </Button>
        );
    };
    const showText = activeFilter ? t('BrandProductTable.showArchived') : t('BrandProductTable.hideArchived');
    return (
        <>
            <Box>
                <PageHeader title={t('BrandProductTable.title')} actions={[addProductButton()]} sx={{ mb: 3 }} />
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        gap: 3,
                        mb: 2,
                    }}>
                    <SearchBar
                        onChange={handleSearchInputChange}
                        value={_.toString(searchValue)}
                        placeholder="Search products"
                        sx={{ width: 360 }}
                    />
                    <Button
                        onClick={handleShowArchived}
                        variant={activeFilter ? 'outlined' : 'contained'}
                        color={activeFilter ? 'secondary' : 'primary'}
                        sx={{
                            paddingX: '16px',
                            height: 44,
                            width: 'auto',
                        }}>
                        {showText}
                    </Button>
                    {shouldShowShopifySync() ? (
                        <Button
                            onClick={importShopify}
                            variant="outlined"
                            color="secondary"
                            sx={{ paddingX: '16px', height: 44, width: 'auto' }}>
                            <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                                <Box>
                                    <img src={shopifyIcon} alt="Shopify" style={{ width: '25px', height: '25px' }} />
                                </Box>
                                {t('BrandProductTable.syncWithShopify')}
                            </Stack>
                        </Button>
                    ) : null}
                </Box>
                <Stack flexDirection="column" sx={{ minHeight: '200px' }}>
                    <ThatchGridPro
                        columns={filteredColumns}
                        rows={products}
                        loading={isLoading}
                        onRowsScrollEnd={handleOnRowsScrollEnd}
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={handleSortModelChange}
                        slots={{
                            loadingOverlay: LinearProgress,
                            noRowsOverlay: () => <EmptyTable message="No products found" />,
                        }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
                        }
                        onRowClick={(params) => {
                            handleRowClick(params);
                        }}
                        onCellClick={(params, event) => handleCellClick(params, event)}
                        sx={{
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                                outlineOffset: '0',
                                outline: 'none !important',
                            },
                        }}
                    />
                </Stack>
            </Box>
            <Box sx={{ padding: 0, position: 'fixed', bottom: 16, right: 16, zIndex: 1000 }}>
                <QuickActionButton />
            </Box>
        </>
    );
}

import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { OrderDiscountTypes } from '../constants/OrderDiscountTypes';

const LOCAL_STORAGE_CART_KEY = 'thatch-carts';

export function getStoredCartContents(key = LOCAL_STORAGE_CART_KEY) {
    const raw = localStorage.getItem(key);
    return JSON.parse(raw);
}

/**
 * Synchronizes the local storage cart with the incoming item.
 * If the item already exists in the local storage and is out of sync, it updates the local storage with the new values.
 * If the item does not exist, it adds the item to the cart in local storage.
 * @param {Object} item - The item to sync with the cart in local storage.
 * @param {string} item.id - The unique identifier of the item.
 * @param {number} item.quantity - The quantity of the item.
 * @param {string} [storageKey=LOCAL_STORAGE_CART_KEY] - The key used to retrieve and update the cart in local storage.
 */
export const syncLocalStorageCart = (item, storageKey = LOCAL_STORAGE_CART_KEY) => {
    // Retrieve the current state from local storage
    const storedCart = localStorage.getItem(storageKey);
    const parsedCart = storedCart ? JSON.parse(storedCart) : { ...initialState, items: [] };

    // Check if the item exists in the cart
    const existingItemIndex = parsedCart.items.findIndex((i) => i.id === item.id);

    if (existingItemIndex > -1) {
        // Update the existing item
        parsedCart.items[existingItemIndex] = { ...parsedCart.items[existingItemIndex], ...item };
    } else {
        // Add the new item if it doesn't exist
        parsedCart.items.push(item);
    }

    // Update local storage
    localStorage.setItem(storageKey, JSON.stringify(parsedCart));
};

/**
 * @typedef OrderItem
 * @property {string} id
 * @property {string} productId
 * @property {number} quantity
 */

// Initial cart state
export const initialState = {
    address: null,
    approvalNote: '',
    business: null, // business.id field of business
    businessId: null, // business.businessId field of business
    /** @type {OrderItem[]} */
    items: [], // Array of cart items
    nonce: null,
    orderRef: null,
    productRefs: [], // Array of product ref items
    retailBusinessId: null, // business.id field of business
    discountAmount: 0,
    discountType: OrderDiscountTypes.DOLLARS,
    shippingCost: 0,
    paymentTerms: null,
};

// Create context
export const CartContext = createContext();

/**
 * Add items to the cart, merging by id and updating the quantity if the item already exists
 * @param {OrderItem[]} items
 * @param {OrderItem[]} newItems
 */
const addItems = (items, newItems) => {
    const merged = [...items];
    newItems.forEach((item) => {
        const index = merged.findIndex((i) => i.id === item.id);
        if (index > -1) {
            merged[index].quantity += item.quantity;
        } else {
            merged.push(item);
        }
    });
    return merged;
};

/**
 * Add product refs to the cart, merging by id if the product already exists
 * @param {Product[]} productRefs
 * @param {Product[]} products
 */
const addProductRefs = (productRefs, products) => {
    const merged = [...productRefs];
    products.forEach((product) => {
        const index = merged.findIndex((p) => p.id === product.id);
        if (index === -1) {
            merged.push(product);
        }
    });
    return merged;
};

/**
 * Reducer to manage cart state changes
 * @param {Object} state
 * @param {OrderItem[]} state.items
 * @param {*} action
 * @returns
 */
export function cartReducer(state, action) {
    switch (action.type) {
        case 'ADD_ITEM': {
            // Logic to add item to cart
            // Update if there is an existing
            const existing = state.items.findIndex((item) => item.id === action.payload.id);
            if (existing > -1) {
                const items = [...state.items.map((i) => ({ ...i }))];
                const { quantity } = items[existing];
                items[existing] = { ...items[existing], quantity: quantity + action.payload.quantity };
                return {
                    ...state,
                    items,
                };
            }
            return {
                ...state,
                business: action.payload.business,
                businessId: action.payload.businessId,
                items: [...state.items, action.payload],
            };
        }
        case 'ADD_NONCE': {
            return {
                ...state,
                nonce: action.payload,
            };
        }
        case 'ADD_ORDER_REF': {
            return {
                ...state,
                orderRef: action.payload,
            };
        }
        case 'ADD_PREVIOUS_ORDER': {
            const { order, nonce, cartAction, products } = action.payload;
            return {
                ...state,
                address: order.addresses[0],
                businessId: order.businessId,
                nonce,
                orderRef: order.id,
                items: (cartAction === 'replace' ? order.items : addItems(state.items, order.items)) ?? [],
                productRefs: cartAction === 'replace' ? products : addProductRefs(state.productRefs, products),
            };
        }
        case 'ADD_PRODUCT_REF': {
            const existing = state.productRefs.findIndex((product) => product.id === action.payload.id);
            if (existing > -1) {
                return state;
            }
            return {
                ...state,
                productRefs: [...state.productRefs, action.payload],
            };
        }
        case 'UPDATE_ITEM': {
            // make a copy of items
            const items = [...state.items.map((i) => ({ ...i }))];
            const index = items.findIndex((item) => item.id === action.payload.id);
            if (items[index]) {
                items[index] = { ...items[index], ...action.payload };
            }
            return {
                ...state,
                items,
            };
        }
        case 'REPLACE': {
            return action.payload;
        }
        case 'REMOVE_ITEM': {
            // Logic to remove item from cart
            const found = state.items.find((item) => item.id === action.payload.id);
            const update = state.items.filter((item) => item.id !== action.payload.id);
            const hasOtherVariantsOfProduct = found && update.find((item) => item.productId === found.productId);

            let productRefsUpdate = state.productRefs;
            if (!hasOtherVariantsOfProduct) {
                productRefsUpdate = productRefsUpdate.filter((p) => p.id !== found.productId);
            }
            return {
                ...state,
                items: update,
                business: !update.length ? null : state.business,
                businessId: !update.length ? null : state.businessId,
                productRefs: !update.length ? [] : productRefsUpdate,
            };
        }
        case 'CLEAR_CART': {
            // Logic to remove business id and all items from cart
            if (action?.payload?.cartId) {
                localStorage.removeItem(action.payload.cartId);
            }
            return {
                ...state,
                ...initialState,
                address: state.address,
            };
        }
        case 'UPDATE_ADDRESS': {
            return {
                ...state,
                address: action.payload,
            };
        }
        case 'UPDATE_RETAIL_BUSINESS': {
            return {
                ...state,
                retailBusinessId: action.payload.businessId,
                retailBusiness: action.payload.id,
                retailBusinessObject: action.payload,
            };
        }
        case 'UPDATE_ORDER_DETAILS': {
            return {
                ...state,
                approvalNote: action.payload.approvalNote,
                discountAmount: action.payload.discountAmount,
                discountType: action.payload.discountType,
                shippingCost: action.payload.shippingCost,
            };
        }
        case 'UPDATE_PAYMENT_TERMS': {
            return {
                ...state,
                paymentTerms: action.payload,
            };
        }

        default:
            return state;
    }
}

export const CartProvider = ({ children, storageKey = LOCAL_STORAGE_CART_KEY }) => {
    const [state, dispatch] = useReducer(cartReducer, initialState, (initial) => {
        const persisted = localStorage.getItem(storageKey);
        const value = persisted ? JSON.parse(persisted) : initial;
        return value;
    });

    useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(state));
    }, [state]);

    useEffect(() => {
        const persisted = localStorage.getItem(storageKey);
        const payload = persisted ? JSON.parse(persisted) : initialState;
        dispatch({ type: 'REPLACE', payload });
    }, [storageKey]);

    // Cart actions
    const addItem = (item) => {
        syncLocalStorageCart(item);
        // Dispatch the add item action
        dispatch({ type: 'ADD_ITEM', payload: item });
    };

    /**
     * Add items from a previous order
     * @param {Order} order
     * @param {string} order.id
     * @param {OrderItem[]} order.items
     * @param {Address[]} order.addresses
     * @param {string} nonce
     * @param {string} cartAction
     */
    const addPreviousOrder = (order, nonce, cartAction, products) => {
        dispatch({ type: 'ADD_PREVIOUS_ORDER', payload: { order, nonce, cartAction, products } });
    };

    const addProductRef = (item) => {
        dispatch({ type: 'ADD_PRODUCT_REF', payload: item });
    };

    const removeItem = (itemId) => {
        dispatch({ type: 'REMOVE_ITEM', payload: { id: itemId } });
    };

    const clearCart = (cartId) => {
        dispatch({ type: 'CLEAR_CART', payload: { cartId } });
    };

    const updateAddress = (payload) => {
        dispatch({ type: 'UPDATE_ADDRESS', payload });
    };

    const updateOrderDetails = (payload) => {
        dispatch({ type: 'UPDATE_ORDER_DETAILS', payload });
    };

    const updateItem = (payload) => {
        dispatch({ type: 'UPDATE_ITEM', payload });
    };

    const updateRetailBusiness = (payload) => {
        dispatch({ type: 'UPDATE_RETAIL_BUSINESS', payload });
    };

    const updatePaymentTerms = (payload) => {
        dispatch({ type: 'UPDATE_PAYMENT_TERMS', payload });
    };

    // Provide state and actions to children
    return (
        <CartContext.Provider
            value={{
                state,
                addItem,
                addPreviousOrder,
                addProductRef,
                removeItem,
                clearCart,
                updateAddress,
                updateOrderDetails,
                updateItem,
                updateRetailBusiness,
                updatePaymentTerms,
            }}>
            {children}
        </CartContext.Provider>
    );
};

CartProvider.propTypes = {
    children: PropTypes.node,
    storageKey: PropTypes.string,
};

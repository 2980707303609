import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PhoneIcon from '../../icons/PhoneIcon.jsx';
import theme, { Teal, Tide } from '../../theme.js';
import EnvelopeIcon from '../../icons/EnvelopeIcon.jsx';
import { useMediaQuery } from '@mui/material';
import InviteStatusChip from '../InviteStatusChip/InviteStatusChip.jsx';
import InviteActionButtons from '../InviteActionButtons/InviteActionButtons.jsx';

export default function DealerInviteInfo({
    invite,
    onResendInvite,
    onSendReminder,
    isReminderLoading,
    isReinviteSending,
}) {
    const invitedBusiness = invite.invitedBusiness;
    const isXsBreakpoint = useMediaQuery(theme.breakpoints.only('xs'));

    const handleResendInvite = () => {
        onResendInvite(invite);
    };

    const handleOrderReminder = () => {
        onSendReminder(invite);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                width: '100%',
            }}>
            <Stack gap={1} sx={{ width: '100%' }}>
                {invitedBusiness ? (
                    <Stack gap={0.5}>
                        <Typography variant="textSm" sx={{ fontWeight: 700 }}>
                            {invitedBusiness.firstName || invitedBusiness.lastName ? (
                                <>
                                    {invitedBusiness.firstName} {invitedBusiness.lastName}
                                    {invitedBusiness.name ? ` | ${invitedBusiness.name}` : ''}
                                </>
                            ) : invitedBusiness.name ? (
                                invitedBusiness.name
                            ) : null}
                        </Typography>

                        <Stack gap={1} direction="row">
                            <EnvelopeIcon sx={{ width: 17, height: 17, color: Teal }} />
                            <Typography variant="textSm" sx={{ color: Tide }}>
                                {invitedBusiness.addresses?.[0]?.emails?.[0]?.default || ''}
                            </Typography>
                        </Stack>
                        <Stack gap={1} direction="row">
                            <PhoneIcon sx={{ width: 17, height: 17, color: Teal }} />
                            <Typography variant="textSm" sx={{ color: Tide }}>
                                {invitedBusiness.addresses?.[0]?.phones?.[0]?.primary || ''}
                            </Typography>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack gap={1} direction="row" sx={{ alignItems: 'center' }}>
                        <EnvelopeIcon sx={{ width: 17, height: 17, color: Teal }} />
                        <Typography variant="textSm" sx={{ color: Tide }}>
                            {invite.username}
                        </Typography>
                    </Stack>
                )}
                {isXsBreakpoint ? (
                    <Stack
                        direction="row"
                        sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <Box sx={{ width: 120 }}>
                            <InviteStatusChip invite={invite} />
                        </Box>
                        <InviteActionButtons
                            invite={invite}
                            onResend={handleResendInvite}
                            onOrderReminder={handleOrderReminder}
                            isLoadingReminder={isReminderLoading}
                            isLoadingResend={isReinviteSending}
                        />
                    </Stack>
                ) : null}
            </Stack>
        </Box>
    );
}

DealerInviteInfo.propTypes = {
    invite: PropTypes.object.isRequired,
    onResendInvite: PropTypes.func,
    onSendReminder: PropTypes.func,
    isReminderLoading: PropTypes.bool,
    isReinviteSending: PropTypes.bool,
};

import * as authProvider from '../../../../providers/auth.js';
import { redirect, useLoaderData, useNavigate } from 'react-router-dom';
import * as businessProvider from '../../../../providers/businessProvider.js';
import * as api from '../../../../api.js';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import Box from '@mui/material/Box';
import React, { useRef, useState } from 'react';
import ShopifyProductCard from '../../../../components/ShopifyProductCard/ShopifyProductCard.jsx';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ShopifyImportModal from '../../../../components/ShopifyImportModal/ShopifyImportModal.jsx';
import { createProductsFromShopify } from '../ProductVariants/ProductVariantTable/productUtil.js';
import ToggleSwitch from '../../../../components/ToggleSwitch/ToggleSwitch.jsx';
import shopifyIcon from '../../../../assets/shopify-logo.svg';
import BackButton from '../../../../components/BackButton/BackButton.jsx';
import ShopsSelectModal from '../../../../components/ShopsSelectModal/ShopsSelectModal.jsx';
import { useError } from '../../../../components/Error/ErrorProvider.jsx';
import PageHeader from '../../../../components/PageHeader/PageHeader.jsx';

export async function loader({ request }) {
    const isAuthenticated = await authProvider.authenticate();
    if (!isAuthenticated) {
        return redirect('/login');
    }
    const business = await businessProvider.getBusiness({ signal: request.signal });
    return {
        business,
    };
}
export default function SyncShopifyProducts() {
    const data = useLoaderData();
    const navigate = useNavigate();
    const { handleError } = useError();
    const { business = {} } = data;
    const [showImportModal, setShowImportModal] = useState(false);
    const [importAll, setImportAll] = useState(true);

    const syncedProducts = useRef([]);
    const [showShopSelection, setShowShopSelection] = useState(true);
    const [shops, setShops] = useState([]);
    const [isLoadingProducts, setIsLoadingProducts] = useState(false);
    const [productsForImport, setProductsForImport] = useState([]);

    const handleImportAllChange = (shouldImport) => {
        if (shouldImport) {
            setProductsForImport(shops);
        } else {
            setProductsForImport([]);
        }
        setImportAll(shouldImport);
    };

    const handleProductSyncChange = (shouldImport, productId) => {
        setProductsForImport((prevProductsForImport) => {
            const productToAdd = getProductById(productId, shops);
            if (!productToAdd) return prevProductsForImport;
            if (shouldImport) {
                const isProductAlreadyAdded = prevProductsForImport.some((shop) =>
                    shop.products.some((product) => product.id === productId)
                );

                if (isProductAlreadyAdded) {
                    return prevProductsForImport;
                }

                const shopToAdd = shops.find((shop) => shop.products.some((product) => product.id === productId));

                if (!shopToAdd) return prevProductsForImport;

                const shopExistsInImport = prevProductsForImport.some((shop) => shop.name === shopToAdd.name);

                if (shopExistsInImport) {
                    return prevProductsForImport.map((shop) => {
                        if (shop.name === shopToAdd.name) {
                            return {
                                ...shop,
                                products: [...shop.products, productToAdd],
                            };
                        }
                        return shop;
                    });
                } else {
                    return [...prevProductsForImport, { ...shopToAdd, products: [productToAdd] }];
                }
            } else {
                return prevProductsForImport.map((shop) => {
                    return {
                        ...shop,
                        products: shop.products.filter((product) => product.id !== productId),
                    };
                });
            }
        });
    };

    const handleSyncProducts = () => {
        syncedProducts.current = productsForImport.map((shop) => {
            return {
                name: shop.name,
                products: createProductsFromShopify(shop.products, business.id),
            };
        });
        setShowImportModal(true);
    };

    const getProductById = (productId, shops) => {
        for (const shop of shops) {
            const foundProduct = shop.products.find((product) => product.id === productId);
            if (foundProduct) {
                return foundProduct;
            }
        }
        return null;
    };

    const isSetToImport = (productId) => {
        return !_.isEmpty(getProductById(productId, productsForImport));
    };

    const handleSwitchShops = () => {
        setShowShopSelection(true);
    };

    const hasAnyProducts = (shops) => {
        return shops.some((shop) => shop.products.length > 0);
    };
    const loadShopProducts = async (shops) => {
        setIsLoadingProducts(true);
        try {
            const linkedShops = business.shopifySessions;
            const filteredShops = linkedShops.filter((linkedShop) => {
                return shops.some((shop) => shop === linkedShop.shop);
            });

            const productPromises = filteredShops.map(async (shop) => {
                const shopifyProducts = await api.getShopifyProducts({
                    query: {
                        shopName: shop.shop.replace('.myshopify.com', ''),
                        accessToken: shop.accessToken,
                    },
                });
                return { name: shop.name, products: shopifyProducts };
            });

            const shopProductsData = await Promise.all(productPromises);
            setShops(shopProductsData);
            setShowShopSelection(false);
            setProductsForImport(shopProductsData);
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoadingProducts(false);
        }
    };

    const handleModalClose = () => {
        setShowImportModal(false);
        navigate('/brand/products');
    };

    return (
        <>
            <PageHeader sx={{ mb: { md: 10 } }} />
            <Box>
                <Stack gap={1.5}>
                    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                        <BackButton />
                        <Button
                            disabled={!hasAnyProducts(productsForImport)}
                            onClick={handleSyncProducts}
                            variant="contained"
                            sx={{ textTransform: 'none' }}>
                            Sync Products
                        </Button>
                    </Stack>
                    <Stack gap={1}>
                        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                            <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                                <Box>
                                    <img src={shopifyIcon} alt="Shopify" style={{ width: '50px', height: '50px' }} />
                                </Box>
                                <Stack direction="row" sx={{ alignItems: 'bottom' }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        All Shopify Products
                                    </Typography>
                                    <Button onClick={handleSwitchShops}>Switch Shops</Button>
                                </Stack>
                            </Stack>
                            <ToggleSwitch isChecked={importAll} onChange={handleImportAllChange} label="Sync All" />
                        </Stack>
                    </Stack>
                    <Box>
                        {shops.map((shop, shopIndex) => (
                            <Stack gap={1} key={shopIndex}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '17px' }}>{shop.name}</Typography>
                                <Stack>
                                    {_.isEmpty(shop.products) ? (
                                        <Typography>No Shopify Products</Typography>
                                    ) : (
                                        shop.products?.map((product) => (
                                            <ShopifyProductCard
                                                key={product.id}
                                                product={product}
                                                onShouldSyncChange={(shouldSync) =>
                                                    handleProductSyncChange(shouldSync, product.id)
                                                }
                                                shouldSync={isSetToImport(product.id)}
                                            />
                                        ))
                                    )}
                                </Stack>
                            </Stack>
                        ))}
                    </Box>
                </Stack>
            </Box>
            <ShopifyImportModal
                open={showImportModal}
                onClose={handleModalClose}
                onCancel={() => setShowImportModal(false)}
                shops={syncedProducts.current}
                businessId={business.businessId}
            />
            <ShopsSelectModal
                shops={business.shopifySessions}
                onContinue={loadShopProducts}
                onClose={() => setShowShopSelection(false)}
                open={showShopSelection}
                isLoading={isLoadingProducts}
            />
        </>
    );
}

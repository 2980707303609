import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { getStoreCreditBalance } from '../../api';

// Reusable currency display component
const CurrencyDisplay = ({ value, ...props }) => (
    <NumericFormat
        value={value}
        displayType="text"
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        prefix="$"
        {...props}
    />
);

CurrencyDisplay.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

// Reusable currency input component
const CurrencyInput = ({ value, onValueChange, ...props }) => (
    <NumericFormat
        value={value}
        onValueChange={({ floatValue }) => onValueChange(floatValue)}
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        prefix="$"
        allowNegative={false}
        customInput={TextField}
        {...props}
    />
);

CurrencyInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onValueChange: PropTypes.func,
};

const RetailerCreditView = ({ busId, retId, handleCredit }) => {
    const [credits, setCredits] = useState(null);
    const [selectedCredit] = useState(null);
    const [businessId] = useState(busId);
    const [retailerId] = useState(retId);

    useEffect(() => {
        loadCredits()
            .then((r) => r)
            .catch((e) => e);
    }, [businessId, retailerId]);

    const loadCredits = async () => {
        try {
            const params = { businessId, retailerId };
            const response = await getStoreCreditBalance(params);
            setCredits(Number(response.data.total));
            handleCredit(Number(response.data.total));
        } catch (err) {
            console.error('Error loading credits:', err, credits, selectedCredit);
        }
    };

    return null;
};

RetailerCreditView.propTypes = {
    handleCredit: PropTypes.func,
    busId: PropTypes.string,
    retId: PropTypes.string,
};

export default RetailerCreditView;

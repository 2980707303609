import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FormInputLabel from '../FormInputLabel/FormInputLabel.jsx';

import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert,
    Box,
    Stack,
    LinearProgress,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { creditRetailer, getStoreCreditBalance } from '../../api';
import TextField from '@mui/material/TextField';
import { toNumber } from 'lodash';
import StoreCreditsIcon from '../../icons/StoreCreditsIcon.jsx';
import IconButton from '@mui/material/IconButton';

const CurrencyDisplay = ({ value, ...props }) => (
    <NumericFormat
        value={value}
        displayType="text"
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        prefix="$"
        {...props}
    />
);

CurrencyDisplay.propTypes = {
    value: PropTypes.number.isRequired,
};

const CurrencyInput = ({ value, onValueChange, ...props }) => (
    <NumericFormat
        value={value}
        onValueChange={({ floatValue }) => onValueChange(floatValue)}
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        prefix="$"
        allowNegative={false}
        customInput={TextField}
        {...props}
    />
);

CurrencyInput.propTypes = {
    value: PropTypes.number.isRequired,
    onValueChange: PropTypes.func,
};

const WholesalerCreditManagement = ({ businessId, retailerId, onCreditAdded }) => {
    const { t } = useTranslation();
    const { storeCreditFeature } = useFlags();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [balance, setBalance] = useState(null);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [creditAmount, setCreditAmount] = useState('');

    useEffect(() => {
        if (storeCreditFeature && retailerId) {
            loadBalance();
        }
    }, [retailerId, storeCreditFeature]);

    const loadBalance = async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await getStoreCreditBalance({ businessId, retailerId });
            setBalance(toNumber(response.data.total));
        } catch (err) {
            if (err.status === 500) {
                setBalance(0);
            } else {
                setError(t('storeCredit.management.errors.loadBalance'));
                console.error('Balance load error:', err);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleAddCredit = async () => {
        try {
            setSubmitLoading(true);
            setError(null);

            const payload = {
                businessId,
                retailerId,
                originalCredit: parseFloat(creditAmount || 0),
            };

            await creditRetailer(payload);
            const newCredit = await loadBalance();
            setAddDialogOpen(false);
            setCreditAmount('');

            if (onCreditAdded) {
                onCreditAdded(newCredit);
            }
        } catch (err) {
            setError(t('storeCredit.management.errors.addCredit'));
            console.error('Add credit error:', err);
        } finally {
            setSubmitLoading(false);
            setCreditAmount('');
        }
    };

    return (
        <Card>
            <CardContent>
                <FormInputLabel text={t('storeCredit.title')} sx={{ fontSize: 12 }} />

                {error && (
                    <Alert
                        severity="error"
                        sx={{ mb: 2 }}
                        action={
                            <Button color="inherit" size="small" onClick={loadBalance}>
                                {t('storeCredit.management.retry')}
                            </Button>
                        }>
                        {error}
                    </Alert>
                )}

                {loading ? (
                    <Box sx={{ width: '100%', height: 48 }}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <Stack>
                        <Box sx={{ width: '100%', height: 18 }} />
                        <Stack
                            onClick={() => setAddDialogOpen(true)}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={1}
                            sx={{ cursor: 'pointer' }}>
                            <Box>
                                <Typography
                                    variant="medium"
                                    sx={{
                                        color: 'var(--Midnight, #24303C)',
                                        fontFamily: 'Inter',
                                        fontSize: 14,
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        lineHeight: '18px',
                                    }}>
                                    <CurrencyDisplay value={balance || 0} />
                                </Typography>
                            </Box>
                            <IconButton sx={{ padding: 0 }} disabled={loading}>
                                <StoreCreditsIcon sx={{ width: 24, height: 24, color: 'common.Tide' }} />
                            </IconButton>
                        </Stack>
                    </Stack>
                )}
            </CardContent>

            <Dialog
                open={addDialogOpen}
                onClose={() => !submitLoading && setAddDialogOpen(false)}
                maxWidth="sm"
                fullWidth>
                <DialogTitle>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        <Typography
                            sx={{
                                // Store Credit
                                color: '#24303C',
                                fontSize: 20,
                                fontFamily: 'Inter',
                                fontWeight: '700',
                                wordWrap: 'break-word',
                                pb: 1,
                            }}>
                            {t('storeCredit.management.dialog.title')}
                        </Typography>
                        <Typography
                            sx={{
                                color: 'var(--Midnight, #24303C)',
                                fontFeatureSettings: "'ss06' on, 'liga' off, 'clig' off",
                                fontFamily: 'Inter',
                                fontSize: 14,
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '18px',
                            }}>
                            Current Balance: <CurrencyDisplay value={balance || 0} />
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Typography
                        sx={{
                            pb: 2,
                            color: 'var(--Teal, #5C7B7D)',
                            fontFeatureSettings: "'ss06' on, 'liga' off, 'clig' off",
                            fontFamily: 'Inter',
                            fontSize: 12,
                            fontStyle: 'normal',
                            fontWeight: 800,
                            lineHeight: '16px',
                            letterSpacing: '2.4px',
                            textTransform: 'uppercase',
                        }}>
                        {t('storeCredit.management.dialog.amount')}
                    </Typography>
                    <CurrencyInput
                        value={creditAmount || 0}
                        onValueChange={(value) => setCreditAmount(value)}
                        fullWidth
                        required
                        error={creditAmount < 0}
                        helperText={creditAmount < 0 && t('storeCredit.management.dialog.amountError')}
                    />
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: 'space-between', // Ensure buttons are evenly spaced
                        padding: '1rem', // Add some padding for a better layout
                    }}>
                    <Button
                        sx={{ width: '100%' }}
                        onClick={() => {
                            setAddDialogOpen(false);
                            setCreditAmount('');
                        }}
                        variant="outlined"
                        color="secondary"
                        disabled={submitLoading}>
                        {t('storeCredit.management.dialog.cancel')}
                    </Button>
                    <Button
                        sx={{ width: '100%' }}
                        variant="contained"
                        onClick={handleAddCredit}
                        disabled={!creditAmount || creditAmount <= 0 || submitLoading}>
                        {submitLoading
                            ? t('storeCredit.management.dialog.adding')
                            : t('storeCredit.management.dialog.add')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

WholesalerCreditManagement.propTypes = {
    retailerId: PropTypes.string.isRequired,
    businessId: PropTypes.string.isRequired,
    onCreditAdded: PropTypes.func,
};

WholesalerCreditManagement.defaultProps = {
    onCreditAdded: () => {},
};

export default WholesalerCreditManagement;

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import * as React from 'react';

export default function AppLayout(props) {
    return (
        <Box>
            <Container {...(props.containerProps ?? {})}>{props.children}</Container>
        </Box>
    );
}

AppLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    containerProps: PropTypes.any,
};

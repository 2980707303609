import ListItemIcon from '@mui/material/ListItemIcon';
import PropTypes from 'prop-types';
import * as React from 'react';
import BrandLayout2 from './BrandLayout2.jsx';

const MobileFriendlyListItemIcon = ({ children, ...rest }) => (
    <ListItemIcon sx={{ minWidth: { xs: 'unset', lg: 56 } }} {...rest}>
        {children}
    </ListItemIcon>
);
MobileFriendlyListItemIcon.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default function BrandLayout() {
    return <BrandLayout2 />;
}

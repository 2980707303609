import { TextField } from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

export default function NumberInput(props) {
    const { min, max, ...rest } = props;
    const isAllowed =
        min !== undefined || max !== undefined
            ? (values) => {
                  const { floatValue } = values;
                  if (min !== undefined && floatValue < min) {
                      return false;
                  }
                  if (max !== undefined && floatValue > max) {
                      return false;
                  }
                  return true;
              }
            : undefined;
    return <NumericFormat customInput={TextField} isAllowed={isAllowed} {...rest} />;
}

NumberInput.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
};

// BrandRoutes.jsx
import { ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from '@sentry/react';
import { t } from 'i18next';
import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { CartProvider } from '../../cart/CartContext';
import { BusinessTypes } from '../../constants/BusinessTypes';
import { awaitAuth } from '../../providers/auth';
import retailTheme from '../../theme.retail.js';
import Settings, { loader as settingsLoader } from '../settings/Settings';
import { SettingsRoutes } from '../settings/SettingsRoutes';
import UserProfile, {
    action as userProfileAction,
    loader as userProfileLoader,
} from '../settings/UserProfile/UserProfile.jsx';
import { loader as brandLoader } from './brand.loader';
import BrandLayout from './BrandLayout';
import { BrandLayoutProvider } from './BrandLayoutProvider.jsx';
import BrandConnections, { loader as brandConnectionsLoader } from './Connections/BrandConnections';
import BrandCreateOrder, { loader as newOrderLoader } from './Orders/BrandCreateOrder';
import {
    WithProvider as BrandCreateOrderDetails,
    action as cartCheckoutAction,
} from './Orders/BrandCreateOrderDetails';
import BrandOrderDetails, {
    loader as brandOrderDetailsLoader,
    action as orderDetailsActions,
} from './Orders/BrandOrderDetails/BrandOrderDetails';
import BrandOrders, { loader as brandOrdersLoader } from './Orders/BrandOrders';
import BrandCreateProduct, {
    loader as brandCreateProductLoader,
} from './Products/BrandCreateProducts/BrandCreateProducts';
import BrandProduct, {
    loader as brandProductDetailsLoader,
    action as brandProductAction,
} from './Products/BrandProduct';
import BrandProductTable, { loader as brandProductTableLoader } from './Products/BrandProductTable/BrandProductTable';
import ProductVariantEdit, {
    loader as editVariantLoader,
    action as editVariantAction,
} from './Products/ProductVariants/ProductVariantEdit/ProductVariantEdit.jsx';
import SyncShopifyProducts, { loader as syncLoader } from './Products/SyncShopifyProducts/SyncShopifyProducts.jsx';
import { action as settingsAction } from '../settings/SettingsContext.jsx';
import PropTypes from 'prop-types';
import DealerPage, { loader as dealerLoader, action as dealerAction } from '../../components/DealerPage/DealerPage.jsx';
import DealerInvites, {
    loader as dealerInvitesLoader,
    action as dealerInvitesAction,
} from '../../components/DealerInvites/DealerInvites.jsx';

const WholeshopThemeProvider = ({ children }) => {
    return <ThemeProvider theme={retailTheme}>{children}</ThemeProvider>;
};

WholeshopThemeProvider.propTypes = {
    children: PropTypes.node,
};

export const BrandRoutes = () => {
    return {
        path: 'brand',
        loader: awaitAuth(brandLoader),
        element: (
            <ErrorBoundary fallback={<div>Brand error</div>} showDialog>
                <BrandLayoutProvider>
                    <WholeshopThemeProvider>
                        <CartProvider storageKey="thatch-self-order-cart">
                            <BrandLayout />
                        </CartProvider>
                    </WholeshopThemeProvider>
                </BrandLayoutProvider>
            </ErrorBoundary>
        ),
        children: [
            {
                path: 'user-profile',
                loader: awaitAuth(userProfileLoader),
                action: userProfileAction,
                element: (
                    <ThemeProvider theme={retailTheme}>
                        <UserProfile />
                    </ThemeProvider>
                ),
            },
            {
                path: 'connections',
                element: (
                    <ErrorBoundary fallback={<div>Brand Connections error</div>} showDialog>
                        <Outlet />
                    </ErrorBoundary>
                ),
                children: [
                    {
                        path: '',
                        index: true,
                        loader: awaitAuth(brandConnectionsLoader),
                        id: 'brand-connections',
                        element: (
                            <ErrorBoundary fallback={<div>Brand Connections error</div>} showDialog>
                                <ThemeProvider theme={retailTheme}>
                                    <BrandConnections />
                                </ThemeProvider>
                            </ErrorBoundary>
                        ),
                    },
                    {
                        path: ':id',
                        id: 'DealerPage',
                        loader: awaitAuth(dealerLoader),
                        action: dealerAction,
                        element: <DealerPage />,
                    },
                    {
                        path: 'invites',
                        id: 'dealer-invites',
                        loader: awaitAuth(dealerInvitesLoader),
                        action: dealerInvitesAction,
                        element: <DealerInvites />,
                        shouldRevalidate: ({ currentUrl, nextUrl }) => {
                            return currentUrl.search !== nextUrl.search;
                        },
                    },
                ],
            },
            {
                path: 'new-order',
                element: <BrandCreateOrder />,
                loader: awaitAuth(newOrderLoader),
            },
            {
                id: 'BrandCreateOrderDetails',
                path: 'new-order/:cartId',
                element: <BrandCreateOrderDetails />,
                action: cartCheckoutAction,
                // errorElement: <CartCheckoutError />,
                handle: {
                    crumb: () => (
                        <Link key="new-order" to="../new-order">
                            {t('BrandCreateOrder.title')}
                        </Link>
                    ),
                },
            },
            {
                path: 'orders',
                id: 'orders',
                element: (
                    <ErrorBoundary fallback={<div>Brand Orders error</div>} showDialog>
                        <Outlet />
                    </ErrorBoundary>
                ),
                children: [
                    {
                        path: '',
                        index: true,
                        loader: awaitAuth(brandOrdersLoader),
                        element: (
                            <ErrorBoundary fallback={<div>Brand Orders error</div>} showDialog>
                                <BrandOrders />
                            </ErrorBoundary>
                        ),
                    },
                    {
                        path: ':orderId',
                        loader: awaitAuth(brandOrderDetailsLoader),
                        action: orderDetailsActions,
                        element: (
                            <ErrorBoundary fallback={<div>Brand Details error</div>} showDialog>
                                <BrandOrderDetails />
                            </ErrorBoundary>
                        ),
                    },
                ],
            },
            {
                path: 'products',
                element: (
                    <ErrorBoundary fallback={<div>Brand Products error</div>} showDialog>
                        <Outlet />
                    </ErrorBoundary>
                ),
                id: 'brand-products',
                loader: awaitAuth(brandProductTableLoader),
                children: [
                    {
                        path: '',
                        element: (
                            <ErrorBoundary fallback={<div>Brand Product Table error</div>} showDialog>
                                <BrandProductTable />
                            </ErrorBoundary>
                        ),
                    },
                ],
            },
            {
                id: 'SyncShopify',
                path: 'products/syncShopify',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        index: true,
                        loader: awaitAuth(syncLoader),
                        element: <SyncShopifyProducts />,
                    },
                ],
            },
            {
                id: 'CreateProduct',
                path: 'products/create-product',
                element: (
                    <ErrorBoundary fallback={<div>Brand Create Product error</div>} showDialog>
                        <Outlet />
                    </ErrorBoundary>
                ),
                children: [
                    {
                        path: '',
                        index: true,
                        loader: awaitAuth(brandCreateProductLoader),
                        element: (
                            <ErrorBoundary fallback={<div>Brand Create Product error</div>} showDialog>
                                <BrandCreateProduct />
                            </ErrorBoundary>
                        ),
                    },
                ],
            },
            {
                id: 'EditProduct',
                path: 'products/:productId',
                element: (
                    <ErrorBoundary fallback={<div>Brand Product ID error</div>} showDialog>
                        <Outlet />
                    </ErrorBoundary>
                ),
                children: [
                    {
                        path: '',
                        index: true,
                        loader: awaitAuth(brandProductDetailsLoader),
                        action: brandProductAction,
                        element: (
                            <ErrorBoundary fallback={<div>Brand Product Details error</div>} showDialog>
                                <BrandProduct />
                            </ErrorBoundary>
                        ),
                    },
                ],
            },
            {
                id: 'EditVariants',
                path: 'products/edit-variants/:productId',
                loader: awaitAuth(editVariantLoader),
                action: editVariantAction,
                element: (
                    <ErrorBoundary fallback={<div>Product Variants error</div>} showDialog>
                        <ProductVariantEdit />
                    </ErrorBoundary>
                ),
            },
            {
                path: 'shopping-cart',
                element: <>shopping-cart</>,
            },
            {
                path: 'connections',
                element: <>connections</>,
            },
            {
                path: 'messages',
                element: <>messages</>,
            },
            {
                path: 'reports',
                element: <>reports</>,
            },
            // TODO: Refactor to use this route instead of the one in router.jsx
            {
                id: 'Settings',
                path: 'brand/settings',
                element: <Settings type={BusinessTypes.BRAND} />,
                children: SettingsRoutes(BusinessTypes.BRAND),
                action: settingsAction,
                loader: awaitAuth(settingsLoader),
            },
        ],
    };
};

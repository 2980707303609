import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { formattedPriceString } from '../../routes/brand/Products/ProductVariants/ProductVariantTable/productUtil.js';
import PropTypes from 'prop-types';

export default function PriceSelectableList(props) {
    const options = [
        { min: 0, max: 49.99 },
        { min: 50, max: 99.99 },
        { min: 100, max: 149.99 },
        { min: 150, max: 299.99 },
        { min: 300, max: Infinity },
    ];
    const { value } = props;
    const initialValues = () => {
        return options.filter((option) => {
            const optionMinIncluded = option.min >= value.min;
            const optionMaxIncluded = value.max === undefined || option.max <= value.max;

            return optionMinIncluded && optionMaxIncluded;
        });
    };
    const [localValue, setLocalValue] = useState(initialValues());

    const isOptionChecked = (option) => {
        return localValue.some((localOption) => localOption.min === option.min && localOption.max === option.max);
    };
    const handleCheckboxChange = (option) => {
        const newValue = isOptionChecked(option)
            ? localValue.filter((localOption) => localOption.min !== option.min || localOption.max !== option.max)
            : [...localValue, option];

        setLocalValue(newValue);
    };

    useEffect(() => {
        let newValue = {};
        if (localValue.length > 0) {
            const { min, max } = localValue.reduce((acc, option) => ({
                min: Math.min(acc.min, option.min),
                max: Math.max(acc.max, option.max ?? acc.max),
            }));
            newValue = { min, max };
        }
        if (props.onChange) {
            props.onChange(newValue);
        }
    }, [localValue]);

    const labelForOption = (option) => {
        if (option.max === Infinity) {
            return `${formattedPriceString(option.min)}+`;
        } else {
            return `${formattedPriceString(option.min)} - ${formattedPriceString(option.max)}`;
        }
    };

    return (
        <FormControl component="fieldset" data-testid="price-selectable-list">
            {options.map((option, index) => (
                <FormControlLabel
                    key={index}
                    control={
                        <Checkbox checked={isOptionChecked(option)} onChange={() => handleCheckboxChange(option)} />
                    }
                    label={labelForOption(option)}
                />
            ))}
        </FormControl>
    );
}

PriceSelectableList.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
};

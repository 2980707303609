import { CircularProgress, IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useLoaderData, useLocation, useNavigate, useNavigation, useRevalidator } from 'react-router-dom';
import * as api from '../../../api';
import LoadingButton from '../../../components/LoadingButton/LoadingButton';
import { BusinessTypes } from '../../../constants/BusinessTypes.jsx';
import { OrderStatuses } from '../../../constants/OrderStatuses';
import { PaymentStatuses } from '../../../constants/PaymentStatuses';
import CaretCircleDownIcon from '../../../icons/CaretCircleDownIcon';
import CheckCircleIcon from '../../../icons/CheckCircleIcon';
import ClipboardTextIcon from '../../../icons/ClipboardTextIcon';
import * as businessProvider from '../../../providers/businessProvider';
import { Midnight, Tide } from '../../../theme';
import CancelOrderButton from './CancelOrderButton';
import ColumnOverlayLayout from './ColumnOverlayLayout';
import OrderCommentsCard from './OrderCommentsCard';
import OrderDetailsHeader from './OrderDetailsHeader';
import OrderProductSummary from './OrderProductSummary';
import OrderSummaryActions from './OrderSummaryActions';
import OrderSummaryDetails from './OrderSummaryDetails';
import OrderSummaryHeader from './OrderSummaryHeader';
import {
    groupOrderItemsByProduct,
    groupProductsByProductId,
    selectItemsByProductId,
    selectProductById,
    selectTotalQuantityOfProductId,
} from './orderUtil';
import OrderAgainButton from './OrderAgainButton.jsx';
import { CartProvider } from '../../../cart/CartContext.jsx';
import FormInputLabel from '../../../components/FormInputLabel/FormInputLabel.jsx';
import ShippedOnLabel from '../../../components/ShippedOnLabel/ShippedOnLabel.jsx';
import OrderInvoiceButton from '../../../components/OrderInvoiceButton/OrderInvoiceButton.jsx';

export async function loader({ params, request }) {
    const url = new URL(request.url);
    const alert = url.searchParams.get('alert');
    const success = url.searchParams.get('success');
    const hasSuccessFlag = success === 'true';
    const defaultBusiness = await businessProvider.getBusiness();
    if (defaultBusiness.type === BusinessTypes.BRAND) {
        return null;
    }

    let order;

    if (hasSuccessFlag) {
        const retryAttempts = 8;
        order = await api.getOrderAfterPayment(params.id, retryAttempts, { signal: request.signal });
    } else {
        order = await api.getOrderById(params.id, { signal: request.signal });
    }

    const productMap = groupOrderItemsByProduct(order);
    // grab the last payment's status
    const paymentStatus = [...(order?.payments || [])].pop()?.status || PaymentStatuses.OPEN;
    const showSuccessAlert = hasSuccessFlag && paymentStatus === PaymentStatuses.COMPLETE;
    const businesses = defaultBusiness?.connectedBusiness || [];
    return {
        alert,
        order,
        business: [defaultBusiness, ...(businesses || [])].filter((b) => b.businessId === order.businessId).pop(),
        hasSuccessFlag,
        paymentStatus,
        showSuccessAlert,
        businessesById: [defaultBusiness, ...(businesses || [])]?.reduce((acc, curr) => {
            return {
                ...acc,
                [curr.businessId]: curr,
            };
        }, {}),
        productMap,
        productsByProductId: groupProductsByProductId(order),
        productIds: Object.keys(productMap) || [],
    };
}

export async function action({ request, params }) {
    let body = {};
    let intent = null;
    if (request.headers.get('content-type')?.includes('application/x-www-form-urlencoded')) {
        body = await request.formData();
        intent = body.get('intent');
    } else {
        body = await request.json();
        intent = body.intent;
    }

    if (intent === 'payNow') {
        const response = await api.payForOrder(params.id);
        window.location = response?.url;
    }
    if (intent === 'createNote') {
        const { businessId, content, createdByBusinessId, createdByUser, orderId } = body;
        const note = {
            businessId,
            content,
            createdByBusinessId,
            createdByUser,
            orderId,
        };
        return api.createOrderNote(note, { signal: request.signal });
    }
    return null;
}

export default function RetailOrderDetails(props) {
    const { t } = useTranslation();
    const data = useLoaderData();
    const { revalidate } = useRevalidator();
    const navigate = useNavigate();
    const { state } = useNavigation();
    const location = useLocation();
    const hideBackButton = data?.alert === 'orderCreated' || false;
    const isLoading = state === 'loading';
    const isSubmitting = state === 'submitting';
    const isPaymentComplete = data?.paymentStatus === PaymentStatuses.COMPLETE;
    const isPaymentFailed = data?.paymentStatus === PaymentStatuses.FAILED;
    const isPaymentPending = data?.paymentStatus === PaymentStatuses.PENDING;
    const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
    const [detailsExpanded, setDetailsExpanded] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(!!data?.alert || false);
    const orderItems = data?.order?.orderItems.map((i) => ({ ...i, quantity: parseInt(i.quantity) })) ?? [];
    const brandBusiness = data?.business;
    const isBusinessPayoutsEnabled = brandBusiness?.settings?.paymentProviderSettings?.payoutsEnabled;

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const onCloseSuccessDialog = () => {
        // Remove search param from url to prevent showing success dialog again
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('success');
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });

        // Remove success dialog
        setSuccessDialogOpen(false);
    };

    React.useEffect(() => {
        if (isPaymentPending) {
            revalidate();
        }
    }, [data?.paymentStatus]);

    React.useEffect(() => {
        setSuccessDialogOpen(data?.showSuccessAlert || false);
    }, [data?.showSuccessAlert]);

    return (
        <Box
            sx={{ px: { xs: 3, sm: 7 }, pt: { xs: 0, sm: 6 }, pb: { xs: detailsExpanded ? '500px' : '194px', md: 8 } }}>
            <OrderDetailsHeader
                business={brandBusiness ?? {}}
                order={data?.order ?? {}}
                hideBackButton={hideBackButton}
            />
            <Box sx={{ my: 4 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 5,
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: '100%',
                            maxWidth: 755,
                            flex: '1 1 auto',
                        }}>
                        {isPaymentFailed ? (
                            <Alert severity="warning" sx={{ width: '100%' }}>
                                {t(`RetailOrderDetails.Alert.orderError`)}
                            </Alert>
                        ) : null}
                        {isPaymentPending ? (
                            <Alert severity="warning" sx={{ width: '100%' }}>
                                <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                                    <Typography>{t(`RetailOrderDetails.Alert.paymentPending`)}</Typography>
                                    <CircularProgress size={12} />
                                </Stack>
                            </Alert>
                        ) : null}
                        <Stack direction="column" gap={3}>
                            <Card>
                                <CardHeader
                                    sx={{ px: '30px', pb: 0, pt: 3 }}
                                    title={
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                                fontWeight: 700,
                                            }}
                                            variant="textLg">
                                            <CheckCircleIcon />
                                            {t('RetailOrderDetails.detailsLabel')}
                                        </Typography>
                                    }
                                />
                                <CardContent sx={{ px: '30px' }}>
                                    <Stack direction="row" sx={{ alignItems: 'top', gap: 1 }}>
                                        <FormInputLabel
                                            gap={0.5}
                                            text={t('RetailOrderDetails.shipToLabel')}
                                            sx={{ fontSize: 10 }}>
                                            <Stack>
                                                <Typography variant="textSm" sx={{ fontWeight: 500 }}>
                                                    {data?.order?.addresses?.[0]?.street1}
                                                </Typography>
                                                <Typography variant="textSm" sx={{ fontWeight: 500 }}>
                                                    {data?.order?.addresses?.[0]?.city},{' '}
                                                    {data?.order?.addresses?.[0]?.state}{' '}
                                                    {data?.order?.addresses?.[0]?.postalCode}
                                                </Typography>
                                            </Stack>
                                        </FormInputLabel>
                                        {!data?.order?.trackingNumbers?.length ? null : (
                                            <FormInputLabel gap={0.5} text="Tracking Details" sx={{ fontSize: 10 }}>
                                                <Stack>
                                                    {data.order.shippedOn ? (
                                                        <ShippedOnLabel date={data.order.shippedOn} />
                                                    ) : null}
                                                    {data?.order?.trackingNumbers?.length > 0
                                                        ? data?.order?.trackingNumbers?.map((i) => (
                                                              <Typography
                                                                  variant="textSm"
                                                                  sx={{ textDecoration: 'underline', color: Tide }}
                                                                  key={i}>
                                                                  #{i}
                                                              </Typography>
                                                          ))
                                                        : '—'}
                                                </Stack>
                                            </FormInputLabel>
                                        )}
                                    </Stack>
                                </CardContent>
                            </Card>
                            <Card sx={{ pb: 3 }}>
                                <CardHeader
                                    sx={{ display: { xs: 'none', sm: 'flex' }, px: '30px', pb: 0, pt: 3 }}
                                    title={
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                                fontWeight: 700,
                                            }}
                                            variant="textLg">
                                            <ClipboardTextIcon />
                                            {t('RetailOrderDetails.itemsLabel')}
                                        </Typography>
                                    }
                                />
                                <CardContent sx={{ px: '30px' }}>
                                    {data?.productIds?.map((id, index) => (
                                        <React.Fragment key={id}>
                                            {index !== 0 ? <Divider sx={{ my: '30px' }} /> : null}
                                            <OrderProductSummary
                                                productTitle={selectProductById(data?.productsByProductId, id)?.title}
                                                quantity={selectTotalQuantityOfProductId(data?.productMap, id)}
                                                items={selectItemsByProductId(data?.productMap, id)}
                                            />
                                        </React.Fragment>
                                    ))}
                                    {!orderItems.length && (
                                        <Box>
                                            <Typography>{t('RetailOrderDetails.emptyLabel')}</Typography>
                                        </Box>
                                    )}
                                </CardContent>
                            </Card>
                            <OrderCommentsCard
                                order={data?.order}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            />
                        </Stack>
                    </Box>
                    {brandBusiness ? (
                        <ColumnOverlayLayout>
                            <Card>
                                <OrderSummaryHeader
                                    action={
                                        <IconButton
                                            sx={{ display: { md: 'none' } }}
                                            onClick={() => setDetailsExpanded(!detailsExpanded)}>
                                            <CaretCircleDownIcon sx={{ color: Midnight, width: 24, height: 24 }} />
                                        </IconButton>
                                    }
                                    business={brandBusiness}
                                />
                                <CardContent sx={{ px: 3 }}>
                                    <OrderSummaryDetails order={data?.order} expanded={detailsExpanded} />
                                    <OrderSummaryActions expanded={detailsExpanded}>
                                        {data?.order?.status === OrderStatuses.IN_REVIEW ? (
                                            <CancelOrderButton
                                                order={data?.order}
                                                onSuccess={() => navigate('.', { replace: true })}
                                            />
                                        ) : null}
                                        {(data?.order?.status === OrderStatuses.APPROVED ||
                                            data?.order?.status === OrderStatuses.SHIPPED) &&
                                        !isPaymentComplete ? (
                                            <Stack gap={1}>
                                                <Form method="post">
                                                    <LoadingButton
                                                        isLoading={isSubmitting || isLoading}
                                                        disabled={
                                                            !isBusinessPayoutsEnabled ||
                                                            isSubmitting ||
                                                            isLoading ||
                                                            isPaymentPending
                                                        }
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        name="intent"
                                                        value="payNow">
                                                        {t('OrderSummaryCard.buttons.payNowLabel')}
                                                    </LoadingButton>
                                                </Form>
                                                {isBusinessPayoutsEnabled ? null : (
                                                    <Alert severity="warning">
                                                        {t('OrderSummaryCard.alert.payoutsNotEnabled')}
                                                    </Alert>
                                                )}
                                            </Stack>
                                        ) : null}
                                        <OrderInvoiceButton order={data?.order} brand={brandBusiness} />
                                        <CartProvider storageKey={`cart-${brandBusiness.id}`}>
                                            <OrderAgainButton
                                                connectionId={brandBusiness.id}
                                                orderId={data?.order.id}
                                            />
                                        </CartProvider>
                                    </OrderSummaryActions>
                                </CardContent>
                            </Card>
                            <OrderCommentsCard order={data?.order} sx={{ display: { xs: 'none', md: 'block' } }} />
                        </ColumnOverlayLayout>
                    ) : (
                        <Card
                            sx={{
                                textAlign: 'center',
                                width: { sm: 240 },
                            }}>
                            <Alert severity="error">
                                <Typography>{t('OrderSummaryCard.missingBusiness')}</Typography>
                            </Alert>
                        </Card>
                    )}
                </Box>
            </Box>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    {data?.alert ? t(`RetailOrderDetails.Alert.${data.alert}`) : null}
                </Alert>
            </Snackbar>
            <Dialog open={successDialogOpen} maxWidth="xs">
                <DialogTitle>{t('RetailOrderDetails.SuccessDialog.title')}</DialogTitle>
                <DialogContent>
                    <Typography>
                        {t('RetailOrderDetails.SuccessDialog.orderLabel')}
                        {data?.order?.id}
                    </Typography>
                    <Typography>{t('RetailOrderDetails.SuccessDialog.description')}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={onCloseSuccessDialog}>
                        {t('RetailOrderDetails.SuccessDialog.buttonLabel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

import Typography from '@mui/material/Typography';
import * as React from 'react';
import { redirect, useLoaderData } from 'react-router-dom';
import * as api from '../../api';
import { BusinessTypes, mapBusinessType } from '../../constants/BusinessTypes';
import * as authProvider from '../../providers/auth';
import * as businessProvider from '../../providers/businessProvider';
import * as userProvider from '../../providers/userProvider';
import BusinessForm from './BusinessForm';
import { useState } from 'react';
import { BRAND_HOME_URL } from '../routesUtil.js';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import BuildingsIcon from '../../icons/BuildingsIcon.jsx';
import WholeshopLogo from '../../icons/WholeshopLogo.jsx';

export async function loader({ request }) {
    const isAuthenticated = await authProvider.authenticate();
    if (!isAuthenticated) {
        return redirect('/login');
    }

    const url = new URL(request.url);
    let businessType = mapBusinessType(url.searchParams.get('business-type')) || null;
    const readOnlyBusinessType = !!businessType;

    if (!Object.values(BusinessTypes).includes(businessType)) {
        businessType = BusinessTypes.RETAIL;
    }

    const [user, business] = await Promise.all([
        userProvider.getUser({ signal: request.signal }),
        businessProvider.getBusiness({ signal: request.signal }),
    ]);
    if (business?.type === BusinessTypes.RETAIL) {
        return redirect(`/retail/connections`);
    } else if (business?.type === BusinessTypes.BRAND) {
        const hasPaymentMethodVerified = business?.settings?.paymentProviderSettings?.paymentProviderAccountRef;
        if (!hasPaymentMethodVerified) {
            return redirect('/business/setup/stripe');
        }
        return redirect(BRAND_HOME_URL);
    }

    return {
        business,
        user,
        businessType,
        readOnlyBusinessType,
    };
}

/**
 * This handles the form submit when user sets initial business info
 * @param {*}
 * @returns
 */
export async function action({ request }) {
    const formData = await request.formData();
    const id = formData.get('_id');
    const businessId = formData.get('businessId');
    const payload = {
        businessId,
        termsAgreement: formData.get('termsAgreement') === 'on',
        type: formData.get('businessType'),
        name: formData.get('businessName'),
        addresses: [
            {
                name: formData.get('businessName'),
                label: 'Primary',
                street1: formData.get('addressLine1'),
                street2: '',
                street3: '',
                city: formData.get('city'),
                state: formData.get('state'),
                postalCode: formData.get('zip'),
                countryCode: formData.get('countryCode'),
                phones: [
                    {
                        primary: formData.get('phone').replace(/\s/g, ''),
                    },
                ],
                emails: [
                    {
                        default: formData.get('email'),
                    },
                ],
            },
        ],
    };
    try {
        const result = await api.updateBusiness(id, payload, { signal: request.signal });
        await api.postSignUp({ signal: request.signal });
        businessProvider.clearCache();
        if (
            result?.type === BusinessTypes.BRAND &&
            !result?.settings?.paymentProviderSettings?.paymentProviderAccountRef
        ) {
            return redirect('/business/setup/stripe');
        }
        return redirect('/');
    } catch (error) {
        // TODO handle server error
    }
    return null;
}

function BusinessSetup() {
    const data = useLoaderData();
    const { business = {} } = data || {};
    const { businessType = BusinessTypes.RETAIL } = data || {};
    const { readOnlyBusinessType = false } = data || {};
    const { t } = useTranslation();
    const [formData] = useState({
        name: '',
        type: '',
        addresses: [],
    });

    return (
        <Stack gap={10} sx={{ height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Stack
                gap={2}
                sx={{
                    alignItems: 'flex-start',
                    width: {
                        xs: '90%',
                        sm: '600px',
                    },
                    mt: 15,
                }}>
                <BuildingsIcon sx={{ color: 'common.Midnight', width: 48, height: 48 }} />
                <Stack gap={2}>
                    <Typography variant="displayMd">{t('BusinessSetup.title')}</Typography>
                    <Typography sx={{ color: 'common.LightGray' }}>{t('BusinessSetup.description')}</Typography>
                    <BusinessForm
                        data={formData}
                        method="PUT"
                        _id={business?.id}
                        businessId={business?.businessId}
                        businessType={businessType}
                        readOnlyBusinessType={readOnlyBusinessType}
                    />
                </Stack>
            </Stack>
            <WholeshopLogo sx={{ height: '25.7px', width: '150px', mb: 5 }} />
        </Stack>
    );
}

export default BusinessSetup;

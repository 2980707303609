import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as Sentry from '@sentry/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import WholeshopLogo from '../../icons/WholeshopLogo.jsx';
import BasketIcon from '../../icons/BasketIcon';
import ChartBarHorizontalIcon from '../../icons/ChartBarHorizontalIcon';
import PackageIcon from '../../icons/PackageIcon';
import StorefrontIcon from '../../icons/StorefrontIcon';
import { Fog, Midnight, SoftBG, Tide } from '../../theme';
import ArrowElbowDownRight from '../../icons/ArrowElbowDownRight.jsx';

const ALERT_HEIGHT = 52;

export default function BrandDrawer(props) {
    const { drawerWidth, open, setOpen, hasAlert } = props;
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [selectedSubIndex, setSelectedSubIndex] = React.useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const flags = useFlags();
    const { t } = useTranslation();

    const theme = useTheme();
    const isMdBreakpoint = useMediaQuery(theme.breakpoints.up('md'));

    React.useEffect(() => {
        // Update selectedIndex based on the current route
        const paths = location.pathname.split('/'); // Split the pathname into segments
        const mainPath = paths[2];
        switch (mainPath) {
            case 'dashboard':
                setSelectedIndex(0);
                break;
            case 'products':
                setSelectedIndex(1);
                break;
            case 'orders':
                setSelectedIndex(2);
                break;
            case 'connections':
                setSelectedIndex(3);
                break;
            default:
                setSelectedIndex(-1); // No selection if the path doesn't match
                Sentry.captureMessage(`Unknown path: ${mainPath}`);
                break;
        }
    }, [location.pathname]);

    const handleNavItemClick = (iconType, index) => () => {
        setSelectedSubIndex(null);
        setSelectedIndex(index);
        setOpen(false);

        switch (iconType) {
            case 'Dashboard':
                navigate('/brand/dashboard');
                break;
            case 'Products':
                navigate('/brand/products?active=true');
                break;
            case 'Orders':
                navigate('/brand/orders');
                break;
            case 'Dealers':
                navigate('/brand/connections');
                break;
            default:
                Sentry.captureMessage(`Unknown iconType: ${iconType}`);
                break;
        }
    };

    const handleNavSubItemClick = (iconType, subIndex) => () => {
        setSelectedSubIndex(subIndex);
        switch (iconType) {
            case 'Invites':
                navigate('/brand/connections/invites');
                break;
            default:
                Sentry.captureMessage(`Unknown iconType: ${iconType}`);
                break;
        }
    };

    return (
        <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}>
            <Drawer
                className="BrandDrawer"
                variant={isMdBreakpoint ? 'permanent' : 'temporary'}
                open={isMdBreakpoint ? true : open}
                onClose={() => setOpen(false)}
                PaperProps={{
                    sx: {
                        bgcolor: SoftBG,
                        border: 'none',
                        borderRadius: '0 24px 24px 0',
                        px: { sm: 2 },
                        boxSizing: 'border-box',
                        top: hasAlert && isMdBreakpoint ? ALERT_HEIGHT : 0,
                        height: hasAlert && isMdBreakpoint ? `calc(100% - ${ALERT_HEIGHT}px)` : '100%',
                    },
                }}
                sx={{
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}>
                <List
                    sx={{
                        pt: 5,
                        '& .MuiListItemButton-root .MuiTypography-root, .MuiListItemIcon-root': {
                            color: Tide,
                            fontWeight: 500,
                        },
                        '& .MuiListItemButton-root': {
                            borderRadius: 2,
                        },
                        '& .MuiListItemButton-root.Mui-selected': {
                            bgcolor: Fog,
                            '& .MuiTypography-root, .MuiListItemIcon-root': {
                                color: Midnight,
                                fontWeight: 700,
                            },
                        },
                        '& .MuiListItemButton-root + .MuiListItemButton-root': {
                            mt: '27px',
                        },
                    }}>
                    <ListItem>
                        <WholeshopLogo sx={{ height: 30 }} />
                    </ListItem>
                    <ListItem className="MuiListItem-placeholder" sx={{ py: 2 }} />
                    {!flags.dashboard ? null : (
                        <ListItemButton selected={selectedIndex === 0} onClick={handleNavItemClick('Dashboard', 0)}>
                            <ListItemIcon>
                                <ChartBarHorizontalIcon sx={{ width: 32, height: 32 }} />
                            </ListItemIcon>
                            <ListItemText primary={t('BrandDrawer.dashboardLabel')} />
                        </ListItemButton>
                    )}
                    <ListItemButton selected={selectedIndex === 2} onClick={handleNavItemClick('Orders', 2)}>
                        <ListItemIcon>
                            <PackageIcon sx={{ width: 32, height: 32 }} />
                        </ListItemIcon>
                        <ListItemText primary={t('BrandDrawer.ordersLabel')} />
                    </ListItemButton>
                    <ListItemButton selected={selectedIndex === 1} onClick={handleNavItemClick('Products', 1)}>
                        <ListItemIcon>
                            <BasketIcon sx={{ width: 32, height: 32 }} />
                        </ListItemIcon>
                        <ListItemText primary={t('BrandDrawer.productsLabel')} />
                    </ListItemButton>
                    <ListItemButton
                        selected={selectedIndex === 3 && selectedSubIndex === null}
                        onClick={handleNavItemClick('Dealers', 3)}>
                        <ListItemIcon>
                            <StorefrontIcon sx={{ width: 32, height: 32 }} />
                        </ListItemIcon>
                        <ListItemText primary={t('BrandDrawer.dealersLabel')} />
                    </ListItemButton>
                    {selectedIndex === 3 ? (
                        <ListItem sx={{ padding: 0 }}>
                            <ListItemButton
                                selected={selectedIndex === 3 && selectedSubIndex === 0}
                                onClick={handleNavSubItemClick('Invites', 0)}>
                                <ListItemIcon sx={{ pl: 2 }}>
                                    <ArrowElbowDownRight />
                                </ListItemIcon>
                                <ListItemText primary={'Invites'} />
                            </ListItemButton>
                        </ListItem>
                    ) : null}
                </List>
            </Drawer>
        </Box>
    );
}

BrandDrawer.propTypes = {
    drawerWidth: PropTypes.number,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    hasAlert: PropTypes.bool,
};

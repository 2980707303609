import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate } from 'react-router-dom';
import * as api from '../../../../api';
import ProductCard from '../../../../components/ProductCard/ProductCard';
import * as businessProvider from '../../../../providers/businessProvider';
import SideCart from '../../../cart/SideCart';
import BusinessHeader from './BusinessHeader';
import ProductFacets from './ProductFacets';
import SlidersIcon from '../../../../icons/SlidersIcon';
import _ from 'lodash';
import CoverPhoto from '../../../../components/CoverPhoto/CoverPhoto.jsx';
import FormInputLabel from '../../../../components/FormInputLabel/FormInputLabel.jsx';
import { useFlags } from 'launchdarkly-react-client-sdk';

const drawerWidth = 287;

export async function loader({ request, params }) {
    const defaultBusiness = await businessProvider.getBusiness();
    const { connectedBusiness } = defaultBusiness;
    const business = [...connectedBusiness].filter((b) => `${b.id}` === `${params.id}`).pop();
    const query = {
        active: true,
        businessIds: business?.businessId,
        limit: 100,
    };

    // apply query strings to api query
    const url = new URL(request.url);
    const categoryIds = url.searchParams.get('categoryIds');
    if (categoryIds) {
        query.categories = categoryIds;
    }
    const maxPrice = url.searchParams.get('maxPrice');
    if (maxPrice) {
        query.maxPrice = maxPrice;
    }
    const minPrice = url.searchParams.get('minPrice');
    if (minPrice) {
        query.minPrice = minPrice;
    }
    const searchString = url.searchParams.get('searchString');
    if (searchString) {
        query.searchString = searchString;
    }
    const productsResult = await api.getProducts({ query, signal: request.signal });
    const categories = await api.getCategoriesForBusiness(params.id, { signal: request.signal });
    return {
        brandId: `${params.id}`,
        business,
        availableCategories: categories,
        products: [...(productsResult?.rows ?? [])],
    };
}

function ConnectedBrandProducts() {
    const { t } = useTranslation();
    const data = useLoaderData();
    const flags = useFlags();
    const { brandId } = data || {};
    const [products, setProducts] = React.useState(data?.products || []);

    const navigate = useNavigate();

    const [isClosing, setIsClosing] = React.useState(false);
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    React.useEffect(() => {
        if (data) {
            setProducts(data?.products);
        }
    }, [data]);

    return (
        <Box>
            <Box sx={{ px: { xs: 3, sm: 7 }, pr: { sm: 4.5 }, pt: { xs: 3, sm: 6 }, pb: { xs: 3 } }}>
                <BusinessHeader business={data?.business} />
            </Box>
            <Box sx={{ display: { sm: 'flex' } }}>
                <Box sx={{ flex: '1 1 auto', px: { xs: 3, sm: 7 }, pr: { sm: 4.5 }, pb: 'calc(30vh)' }}>
                    {!_.isEmpty(data?.business?.coverPhoto) ? (
                        <Box sx={{ mb: 3 }}>
                            <CoverPhoto image={data.business.coverPhoto} />
                        </Box>
                    ) : null}
                    {!_.isEmpty(data?.business?.aboutUs) ? (
                        <Box sx={{ mb: 3 }}>
                            <FormInputLabel text={t('ConnectedBrandProducts.aboutBrandLabel')}>
                                <Typography variant="textSm" sx={{ fontWeight: 500, maxWidth: 558 }}>
                                    {data?.business?.aboutUs}
                                </Typography>
                            </FormInputLabel>
                        </Box>
                    ) : null}
                    <Toolbar sx={{ display: { sm: 'none' } }} />
                    <Stack
                        justifyContent="space-between"
                        gap={2}
                        sx={{
                            alignItems: {
                                xs: 'flex-start',
                                md: 'center',
                            },
                            flexDirection: {
                                xs: 'column',
                                md: 'row',
                            },
                            mb: 4,
                        }}>
                        {flags.showCategoryFiltersButton ? (
                            <Button
                                sx={{
                                    border: (theme) => `1px solid ${theme.palette.common.Misty}`,
                                    borderRadius: 50,
                                    px: 2,
                                    py: 1.5,
                                    display: 'inline-flex',
                                    gap: 1,
                                }}
                                variant="outlined"
                                onClick={handleDrawerToggle}>
                                <SlidersIcon sx={{ width: 24, height: 24, color: 'common.Midnight' }} />
                                <Typography
                                    component="span"
                                    sx={{
                                        color: 'common.Midnight',
                                        fontSize: '10px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: 'normal',
                                        letterSpacing: '2px',
                                        textTransform: 'uppercase',
                                    }}>
                                    {t('ConnectedBrandProducts.buttons.allFiltersLabel')}
                                </Typography>
                            </Button>
                        ) : null}
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontWeight: 700,
                                lineHeight: 'normal',
                                letterSpacing: '0.6px',
                                textTransform: 'uppercase',
                            }}>
                            {t('ConnectedBrandProducts.productsLabel', { count: products?.length ?? 0 })}
                        </Typography>
                    </Stack>
                    {!products?.length && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography>{t('ConnectedBrandProducts.emptyProductsLabel')}</Typography>
                        </Box>
                    )}
                    <Stack sx={{ flexWrap: 'wrap', flexDirection: 'row', gap: { xs: 2, sm: 3 } }}>
                        {products?.map((i) => (
                            <Box key={i.id}>
                                <ProductCard
                                    product={i}
                                    brandId={brandId}
                                    onClick={() => navigate(`/retail/connections/${brandId}/products/${i.id}`)}
                                />
                            </Box>
                        ))}
                    </Stack>
                </Box>
                <Drawer
                    anchor="left"
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        '& .MuiDrawer-paper': {
                            borderRadius: '16px',
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            left: 0,
                            top: 'auto',
                            zIndex: 1,
                        },
                    }}>
                    <Toolbar variant="retail" data-info="Toolbar-placeholder" />
                    <ProductFacets />
                </Drawer>
                <SideCart />
            </Box>
        </Box>
    );
}

export default ConnectedBrandProducts;

import React from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Button from '@mui/material/Button';
import { LightGray } from '../../theme.js';

export default function PDFGenerator({ filename = '', children, footer, onGenerate, text, sx, variant, color }) {
    const generatePdf = async (component) => {
        try {
            // Create a hidden container for rendering the component
            const hiddenContainer = document.createElement('div');
            Object.assign(hiddenContainer.style, {
                position: 'absolute',
                top: '-10000px', // Move it far off-screen when creating so it's not visible
                left: '-10000px',
                width: '800px',
                padding: '20px',
                backgroundColor: 'white',
                zIndex: '-1',
                fontFamily: 'Inter, SalisburyBold',
            });
            document.body.appendChild(hiddenContainer);

            // Create a hidden container for rendering the footer
            const footerContainer = document.createElement('div');
            Object.assign(footerContainer.style, {
                position: 'absolute',
                top: '-10000px', // Move it far off-screen
                left: '-10000px',
                width: '800px',
                backgroundColor: 'white',
            });
            document.body.appendChild(footerContainer);

            // Render the main content and footer into the hidden containers
            const root = createRoot(hiddenContainer);
            const footerRoot = createRoot(footerContainer);
            let isRendered = false;

            const Wrapper = React.forwardRef((_, ref) => (
                <div
                    ref={ref}
                    style={{
                        width: '100%',
                        padding: '20px',
                        fontFamily: 'Inter, SalisburyBold',
                    }}>
                    {component}
                </div>
            ));
            Wrapper.displayName = 'PDFWrapper';

            root.render(<Wrapper ref={() => (isRendered = true)} />);
            footerRoot.render(footer);

            // Wait for rendering and fonts to load
            const waitForRender = () =>
                new Promise((resolve) => {
                    const checkRender = () => {
                        if (isRendered) {
                            resolve();
                        } else {
                            requestAnimationFrame(checkRender);
                        }
                    };
                    checkRender();
                });

            await waitForRender();
            await document.fonts.ready;

            // Capture the main content as a canvas
            const canvas = await html2canvas(hiddenContainer, {
                useCORS: true,
                scale: 2,
                allowTaint: false,
                logging: true,
            });

            // Capture the footer as a canvas
            const footerCanvas = footer
                ? await html2canvas(footerContainer, {
                      useCORS: true,
                      scale: 2,
                      allowTaint: false,
                  })
                : null;

            // Clean up DOM elements
            root.unmount();
            footerRoot.unmount();
            document.body.removeChild(hiddenContainer);
            document.body.removeChild(footerContainer);

            // Generate the PDF with pagination
            // eslint-disable-next-line new-cap
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 297; // A4 height in mm
            const headerHeight = 20; // Blank header height in mm
            const footerHeight = 15; // Footer height in mm
            const pageCanvasHeight = canvas.width * ((pageHeight - headerHeight - footerHeight) / imgWidth);

            let position = 0;
            let pageNumber = 1;

            // Calculate total number of pages
            const totalPages = Math.ceil(canvas.height / pageCanvasHeight);

            while (position < canvas.height) {
                if (pageNumber > 1) {
                    // Add blank header for all pages except the first
                    pdf.setFillColor(255, 255, 255);
                    pdf.rect(0, 0, imgWidth, headerHeight, 'F');
                }

                const canvasPage = document.createElement('canvas');
                canvasPage.width = canvas.width;
                canvasPage.height = Math.min(pageCanvasHeight, canvas.height - position);

                const ctx = canvasPage.getContext('2d');
                ctx.drawImage(
                    canvas,
                    0,
                    position,
                    canvas.width,
                    canvasPage.height,
                    0,
                    0,
                    canvas.width,
                    canvasPage.height
                );

                const imgData = canvasPage.toDataURL('image/png');
                pdf.addImage(
                    imgData,
                    'PNG',
                    0,
                    pageNumber > 1 ? headerHeight : 0,
                    imgWidth,
                    (canvasPage.height * imgWidth) / canvas.width
                );

                // Add divider above the footer
                const dividerY = pageHeight - footerHeight - 2;
                pdf.setDrawColor(LightGray);
                pdf.setLineWidth(0.1);
                pdf.line(10, dividerY, imgWidth - 10, dividerY);

                // Add footer with proper aspect ratio
                let footerCenterY = pageHeight - footerHeight / 2;
                if (footerCanvas) {
                    const footerCanvasWidth = footerCanvas.width;
                    const footerCanvasHeight = footerCanvas.height;

                    const footerAspectRatio = footerCanvasHeight / footerCanvasWidth;
                    const scaledFooterHeight = (imgWidth - 20) * footerAspectRatio;

                    footerCenterY = pageHeight - scaledFooterHeight / 2 - 5;

                    pdf.addImage(
                        footerCanvas.toDataURL('image/png'),
                        'PNG',
                        10,
                        pageHeight - scaledFooterHeight - 5,
                        imgWidth - 20,
                        scaledFooterHeight
                    );
                }

                // Add page number and total pages
                pdf.setFontSize(8);
                pdf.text(`Page ${pageNumber} of ${totalPages}`, imgWidth - 10, footerCenterY, { align: 'right' });

                position += canvasPage.height;
                pageNumber++;

                if (position < canvas.height) {
                    pdf.addPage();
                }
            }

            // Open the PDF in a new browser window
            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Hint for the filename in the URL
            const pdfViewerUrl = `${pdfUrl}#filename=${encodeURIComponent(filename)}`;

            // Open the PDF in a new window
            window.open(pdfViewerUrl, '_blank');

            if (onGenerate) onGenerate();

            if (onGenerate) onGenerate();
        } catch (error) {
            console.error('PDF generation failed:', error);
        }
    };

    return (
        <Button variant={variant} color={color} sx={sx} onClick={() => generatePdf(<div>{children}</div>)}>
            {text || 'Generate PDF'}
        </Button>
    );
}

PDFGenerator.propTypes = {
    children: PropTypes.node.isRequired,
    footer: PropTypes.node,
    onGenerate: PropTypes.func,
    text: PropTypes.string,
    sx: PropTypes.object,
    variant: PropTypes.any,
    color: PropTypes.any,
    filename: PropTypes.string,
};

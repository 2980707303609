import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Button, Dialog, useMediaQuery } from '@mui/material';
import Stack from '@mui/material/Stack';
import PlusCircleIcon from '../../icons/PlusCircleIcon.jsx';
import { useTranslation } from 'react-i18next';
import SearchBar from '../SearchBar/SearchBar.jsx';
import InviteStatusSelect from '../inviteStatusSelect/InviteStatusSelect.jsx';
import { useActionData, useLoaderData, useNavigation, useSearchParams, useSubmit } from 'react-router-dom';
import * as api from '../../api.js';
import ThatchGridPro from '../ThatchGridPro/ThatchGridPro.jsx';
import Box from '@mui/material/Box';
import InviteStatusChip from '../InviteStatusChip/InviteStatusChip.jsx';
import { format } from 'date-fns-tz';
import DealerInviteInfo from './DealerInviteInfo.jsx';
import IconButton from '@mui/material/IconButton';
import CircleXIcon from '../../icons/CircleXIcon.jsx';
import InviteBusiness from '../InviteBusiness/InviteBusiness.jsx';
import { useLoaderVisibility } from '../../routes/root/Root.jsx';
import { useError } from '../Error/ErrorProvider.jsx';
import LoadingButton from '../LoadingButton/LoadingButton.jsx';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import InfiniteScroller from '../../helpers/InfiniteScroller.jsx';
import theme from '../../theme.js';
import InviteActionButtons from '../InviteActionButtons/InviteActionButtons.jsx';

function getParams({ request, page }) {
    const url = new URL(request.url);
    const searchString = url.searchParams.get('search') || '';
    const dealerId = url.searchParams.get('businessId');
    const status = url.searchParams.get('status');
    const sort = url.searchParams.get('sort');
    const limit = 20;

    const params = {
        query: {
            offset: page,
            limit,
        },
    };

    if (searchString) {
        params.query.search = searchString;
    }

    if (dealerId) {
        params.query.businessId = dealerId;
    }

    if (status) {
        params.query.status = status;
    }

    if (sort) {
        params.query.sort = sort;
    }

    return params;
}

export async function loader({ request }) {
    // This loader only revalidates when query params change. Check route's shouldValidate in router.
    try {
        const params = getParams({ request, page: 0 });
        const response = await api.getInvites(params);
        const invites = response.data.invites;
        return {
            invites,
            totalInvites: response.data.total,
            pending: response.data.pending,
        };
    } catch (error) {
        return { error };
    }
}

export async function action({ request }) {
    try {
        const body = await request.json();
        const { intent } = body;

        if (intent === 'resendInvite' || intent === 'resendInvites') {
            const inviteIds = body.inviteIds;
            const response = await api.resendInvite(inviteIds, { request });
            return { intent, response };
        }

        if (intent === 'loadMore') {
            const page = body.page;
            const params = getParams({ request, page });
            const response = await api.getInvites(params);
            return {
                intent,
                invites: response.data.invites,
                totalInvites: response.data.total,
                pending: response.data.pending,
            };
        }

        if (intent === 'sendOrderReminder') {
            const payload = { email: body.email };
            const businessId = body.businessId;
            if (businessId) {
                payload.invitedBusinessId = businessId;
            }
            const response = await api.sendOrderReminder(payload, { request });
            return { intent, response };
        }

        return null;
    } catch (error) {
        return { error };
    }
}

export default function DealerInvites() {
    const { invites: initialInvites = [], totalInvites = 0, pending } = useLoaderData();
    const [invites, setInvites] = useState(initialInvites);
    const { t } = useTranslation();
    const [params, setParams] = useSearchParams();
    const [status, setStatus] = React.useState(params.get('status'));
    const [search, setSearch] = useState(params.get('search') || '');
    const [showInviteBusiness, setShowInviteBusiness] = useState(false);
    const { setHideRootLoader } = useLoaderVisibility();
    const navigation = useNavigation();
    const submit = useSubmit();
    const isLoading = navigation.state === 'loading' || navigation.state === 'submitting';
    const isResendingAllInvites = isLoading && navigation.json?.intent === 'resendInvites';
    const isLoadingMoreInvites = isLoading && navigation.json?.intent === 'loadMore';
    const actionData = useActionData();
    const { handleError } = useError();
    const [showSuccess, setShowSuccess] = useState(false);
    const [showReminderSuccess, setShowReminderSuccess] = useState(false);
    const pageRef = useRef(0);
    const hasMore = invites.length !== totalInvites;
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));

    const appendInvites = (newInvites, appendToEnd = true) => {
        setInvites((existingInvites) => {
            // Combine invites based on the appendToEnd flag
            const combinedInvites = appendToEnd
                ? [...existingInvites, ...newInvites] // Append to the end
                : [...newInvites, ...existingInvites]; // Prepend to the beginning

            // Ensure uniqueness
            const uniqueInvites = Array.from(new Set(combinedInvites.map((invite) => JSON.stringify(invite)))).map(
                (inviteStr) => JSON.parse(inviteStr)
            );

            return uniqueInvites;
        });
    };

    useEffect(() => {
        setHideRootLoader(true);

        return () => {
            setHideRootLoader(false);
        };
    }, [setHideRootLoader]);

    useEffect(() => {
        if (actionData) {
            if (actionData.error) {
                handleError(actionData.error);
            } else if (actionData.intent === 'resendInvites' || actionData.intent === 'resendInvite') {
                setShowSuccess(true);
            } else if (actionData.intent === 'loadMore') {
                appendInvites(actionData.invites);
            } else if (actionData.intent === 'sendOrderReminder') {
                setShowReminderSuccess(true);
            }
        }
    }, [actionData]);

    useEffect(() => {
        setInvites(initialInvites);
    }, [initialInvites]);

    const handleStatusChange = (e) => {
        const status = e.target.value;
        pageRef.current = 0;
        setStatus(status);
        if (status === t('OrderStatusSelect.emptyLabel')) {
            params.delete('status');
        } else {
            params.set('status', status);
        }

        setParams(params);
    };

    const handleSearchChange = (e) => {
        const search = e.target.value;
        setSearch(search);
        // debounce
        setTimeout(() => {
            pageRef.current = 0;
            if (search === '' || search === null) {
                params.delete('search');
            } else {
                params.set('search', search);
            }
            setParams(params);
        }, 500);
    };

    const resendInvite = (invite) => {
        submit(
            {
                intent: 'resendInvite',
                inviteIds: [invite.id],
            },
            { method: 'post', encType: 'application/json' }
        );
    };

    const resendAllInvites = () => {
        submit(
            {
                intent: 'resendInvites',
            },
            { method: 'post', encType: 'application/json' }
        );
    };

    const isResendingInviteForId = (inviteId) => {
        return (
            isLoading &&
            navigation.json?.intent === 'resendInvite' &&
            navigation.json?.inviteIds.some((id) => id === inviteId)
        );
    };

    const isSendingOrderReminderForId = (inviteId) => {
        return isLoading && navigation.json?.intent === 'sendOrderReminder' && navigation.json?.businessId === inviteId;
    };

    const loadMore = () => {
        if (hasMore) {
            pageRef.current = pageRef.current + 1;
            submit(
                {
                    intent: 'loadMore',
                    page: pageRef.current,
                },
                { method: 'post', encType: 'application/json' }
            );
        }
    };

    const handleInviteSent = (invites) => {
        setTimeout(() => {
            setShowInviteBusiness(false);
            appendInvites(invites, false);
        }, 1000);
    };

    const handleOrderReminder = (invite) => {
        submit(
            {
                intent: 'sendOrderReminder',
                businessId: invite.invitedBusiness.businessId,
                email: invite.username,
            },
            { method: 'post', encType: 'application/json' }
        );
    };

    const columns = [
        {
            field: 'dealer',
            headerName: 'DEALER INFO',
            width: 250,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => (
                <DealerInviteInfo
                    invite={params.row}
                    onResendInvite={resendInvite}
                    onSendReminder={handleOrderReminder}
                    isReinviteSending={isResendingInviteForId(params.row.id)}
                    isReminderLoading={isSendingOrderReminderForId(params.row.invitedBusiness?.businessId)}
                />
            ),
        },
        {
            field: 'createdAt',
            headerName: 'INVITED ON',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <Typography variant="textSm">{format(new Date(params.row.updatedAt), 'MM/dd/yyyy')} </Typography>
            ),
        },
        {
            field: 'status',
            headerName: 'STATUS',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <InviteStatusChip invite={params.row} />,
        },
        {
            field: 'orderPlaced',
            headerName: 'ORDER PLACED',
            width: 168,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <Typography variant="textSm">{params.row.orderPlaced ? 'Yes' : 'No'}</Typography>,
        },
        {
            field: 'action',
            headerName: '',
            width: 200,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <InviteActionButtons
                    invite={params.row}
                    onResend={resendInvite}
                    isLoadingResend={isResendingInviteForId(params.row.id)}
                    isLoadingReminder={isSendingOrderReminderForId(params.row.invitedBusiness?.businessId)}
                    onOrderReminder={handleOrderReminder}
                />
            ),
        },
    ];

    const visibleColumns = isSmBreakpoint ? columns : columns.filter((col) => col.field === 'dealer');

    return (
        <>
            <Stack gap={3} sx={{ maxWidth: 970 }}>
                <Stack
                    gap={2}
                    direction="row"
                    sx={{
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                    }}>
                    <Typography variant="displaySm">{t('DealerInvites.title')}</Typography>
                    <Button
                        onClick={() => setShowInviteBusiness(true)}
                        color="secondary"
                        variant="outlined"
                        sx={{
                            paddingX: '16px',
                            height: 44,
                            width: 'auto',
                        }}>
                        <Stack gap={1} direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <PlusCircleIcon sx={{ width: 24, height: 24 }} />
                            <Typography
                                noWrap
                                variant="textSm"
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {t('DealerInvites.inviteDealer')}
                            </Typography>
                        </Stack>
                    </Button>
                </Stack>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    gap={2}
                    sx={{
                        alignItems: { xs: 'flex-start', sm: 'center' },
                        justifyContent: 'space-between',
                    }}>
                    <Stack gap={2} direction={{ xs: 'column', sm: 'row' }} sx={{ alignItems: 'center', width: '100%' }}>
                        <SearchBar
                            value={search}
                            onChange={handleSearchChange}
                            placeholder={t('DealerInvites.searchPlaceholder')}
                            sx={{ width: { xs: '100%', sm: '45%' } }}
                        />
                        <Stack
                            direction="row"
                            gap={2}
                            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                            <InviteStatusSelect
                                defaultValue={status}
                                selectProps={{ size: 'small' }}
                                onChange={handleStatusChange}
                            />
                            <LoadingButton
                                isLoading={isResendingAllInvites}
                                onClick={resendAllInvites}
                                variant="outlined"
                                color="secondary"
                                sx={{
                                    height: 32,
                                    minWidth: 170,
                                    fontSize: 12,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>
                                {t('DealerInvites.resendInvites', { pendingCount: pending > 0 ? ` (${pending})` : '' })}
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </Stack>
                <InfiniteScroller loadNext={loadMore} loading={isLoadingMoreInvites} hasMore={hasMore}>
                    <ThatchGridPro columns={visibleColumns} rows={invites} autoHeight disableRowSelectionOnClick />
                </InfiniteScroller>
            </Stack>
            <Dialog open={showInviteBusiness} onClose={() => setShowInviteBusiness(false)} fullWidth>
                <Box sx={{ width: '100%', maxWidth: 600, position: 'relative' }}>
                    <IconButton
                        onClick={() => setShowInviteBusiness(false)}
                        sx={{ position: 'absolute', top: 8, right: 8 }}>
                        <CircleXIcon />
                    </IconButton>
                    <InviteBusiness onSuccess={handleInviteSent} />
                </Box>
            </Dialog>
            <Snackbar
                open={showSuccess}
                autoHideDuration={6000}
                onClose={() => setShowSuccess(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setShowSuccess(false)} sx={{ width: '100%' }}>
                    {t('DealerInvites.invitesSent')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={showReminderSuccess}
                autoHideDuration={6000}
                onClose={() => setShowReminderSuccess(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setShowReminderSuccess(false)} sx={{ width: '100%' }}>
                    {t('DealerInvites.orderReminderSent')}
                </Alert>
            </Snackbar>
        </>
    );
}

DealerInvites.propTypes = {};

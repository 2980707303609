import React, { createContext, useContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const ErrorContext = createContext({});
export default function ErrorProvider({ children }) {
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleError = (error) => {
        let message = '';
        if (typeof error === 'string') {
            message = error;
        } else {
            message = error?.message || 'An unknown error has occurred';
        }
        console.error('Error: ', message);
        setErrorMessage(message);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <ErrorContext.Provider value={{ handleError }}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="error">
                    <Typography sx={{ whiteSpace: 'pre-line' }}>{errorMessage}</Typography>
                </Alert>
            </Snackbar>
        </ErrorContext.Provider>
    );
}

export function useError() {
    return useContext(ErrorContext);
}

ErrorProvider.propTypes = {
    children: PropTypes.any,
};

import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { formatDate } from '../../routes/retail/orders/OrderDetailsHeader.jsx';
import Divider from '@mui/material/Divider';
import { LightGray, Midnight70, Teal } from '../../theme.js';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { formattedPriceString } from '../../routes/brand/Products/ProductVariants/ProductVariantTable/productUtil.js';
import OrderTable from './OrderTable.jsx';
import { dueDateForOrder } from '../PaymentDueDate/paymentTermsUtils.js';
import { useTranslation } from 'react-i18next';

export default function OrderInvoice({ order, brand }) {
    const { t } = useTranslation();
    const labelStyle = {
        color: Midnight70,
        letterSpacing: 3,
        fontFamily: 'Inter, Arial, sans-serif',
        fontWeight: 'bold',
        fontSize: 8,
        textTransform: 'uppercase',
    };

    const addressStack = (address) => {
        return (
            <Stack gap={0.5}>
                <Typography sx={{ fontWeight: 700, fontSize: 12 }}>{address.name}</Typography>
                <Typography sx={{ fontWeight: 700, fontSize: 12 }}>{address.emails?.[0].default}</Typography>
                <Typography sx={{ fontWeight: 500, fontSize: 12 }}>
                    {address.street1}, {address.city}
                </Typography>
                <Typography sx={{ fontWeight: 500, fontSize: 12 }}>
                    {address.postalCode}, {address.state}
                </Typography>
                <Typography sx={{ fontWeight: 500, fontSize: 12 }}>
                    {formatPhoneNumber(address?.phones?.[0]?.primary)}
                </Typography>
            </Stack>
        );
    };

    const brandAddress = () => {
        return (
            brand.addresses?.find((address) => address.label === 'Billing' || address.label === 'billing') ||
            brand.addresses?.[0]
        );
    };

    const orderDueDate = () => {
        return dueDateForOrder(order);
    };

    const isDraft = () => {
        return orderDueDate() === null;
    };

    const formatPhoneNumber = (value) => {
        const phoneNumber = parsePhoneNumberFromString(value || '');
        return phoneNumber ? phoneNumber.formatNational() : value;
    };

    const orderLabel = (title, value) => {
        return (
            <Stack direction="row" sx={{ alignItems: 'center', width: 250, justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: 10, fontFamily: 'Inter', fontWeight: 500, color: Teal }}>
                    {title}
                </Typography>
                <Typography sx={{ fontSize: 10, fontFamily: 'Inter', fontWeight: 700 }}>{value}</Typography>
            </Stack>
        );
    };

    return (
        <Stack gap={3}>
            <Stack direction="row" sx={{ alignItems: 'top', justifyContent: 'space-between' }}>
                <Typography
                    variant="DisplayMd"
                    sx={{
                        fontSize: 20,
                        fontFamily: 'SalisburyBold, Arial, sans-serif',
                    }}>
                    {t('OrderInvoice.orderNumberLabel', { orderId: order.id })}
                </Typography>
                {isDraft() ? (
                    <Typography
                        sx={{
                            fontSize: 16,
                            textTransform: 'uppercase',
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            color: LightGray,
                        }}>
                        {t('OrderInvoice.draftLabel')}
                    </Typography>
                ) : null}
            </Stack>
            <Stack gap={1}>
                <Stack gap={2} direction="row">
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontFamily: 'Inter',
                        }}>
                        {t('OrderInvoice.invoiceDateLabel')}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            fontFamily: 'Inter',
                        }}>
                        {formatDate(order.createdAt)}
                    </Typography>
                </Stack>
                {orderDueDate() ? (
                    <Stack gap={2} direction="row">
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontFamily: 'Inter',
                            }}>
                            {t('OrderInvoice.dueDateLabel')}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                fontFamily: 'Inter',
                            }}>
                            {formatDate(orderDueDate())}
                        </Typography>
                    </Stack>
                ) : null}
            </Stack>
            <Divider />
            <Stack direction="row" sx={{ width: '100%', alignItems: 'top' }}>
                <Stack gap={1} sx={{ width: 300 }}>
                    <Typography sx={labelStyle}>{t('OrderInvoice.billedToLabel')}</Typography>
                    {addressStack(order.addresses[0])}
                </Stack>
                <Stack gap={1} sx={{ width: 300 }}>
                    <Typography sx={labelStyle}>{t('OrderInvoice.shippingAddressLabel')}</Typography>
                    {addressStack(order.addresses[0])}
                </Stack>
                <Stack gap={1}>
                    <Typography sx={labelStyle}>{t('OrderInvoice.soldByLabel')}</Typography>
                    <Stack gap={0.5}>
                        <Typography sx={{ fontWeight: 700, fontSize: 12 }}>{brand.name}</Typography>
                        <Typography sx={{ fontWeight: 700, fontSize: 12 }}>
                            {brandAddress().emails?.[0].default}
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: 12 }}>
                            {brandAddress().street1}, {brandAddress().city}
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: 12 }}>
                            {brandAddress().postalCode}, {brandAddress().state}
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: 12 }}>
                            {formatPhoneNumber(brandAddress()?.phones?.[0]?.primary)}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <OrderTable order={order} />
            <Stack gap={2} sx={{ alignItems: 'flex-end' }}>
                {orderLabel(
                    t('OrderInvoice.quantityLabel'),
                    order.orderItems.reduce((sum, orderItem) => sum + Number(orderItem.quantity), 0)
                )}
                {orderLabel(t('OrderInvoice.subTotalLabel'), formattedPriceString(Number(order.subTotal)))}
                {orderLabel(t('OrderInvoice.shippingLabel'), formattedPriceString(`${order.shippingCost}`))}
                {orderLabel(t('OrderInvoice.discountLabel'), formattedPriceString(order.discountAmount))}
                {orderLabel(t('OrderInvoice.storeCreditLabel'), formattedPriceString(Number(order.storeCredit)))}
                {order.paymentTermData ? orderLabel(t('OrderInvoice.termsLabel'), order.paymentTermData.title) : null}
                <Divider sx={{ width: '37%' }} />
                <Stack direction="row" sx={{ alignItems: 'center', width: 250, justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: 12, fontFamily: 'Inter', fontWeight: 500 }}>
                        {t('OrderInvoice.orderTotalLabel')}
                    </Typography>
                    <Typography sx={{ fontSize: 12, fontFamily: 'Inter', fontWeight: 700 }}>
                        {formattedPriceString(`${order.total}`)}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
}

OrderInvoice.propTypes = {
    order: PropTypes.object.isRequired,
    brand: PropTypes.object.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { PaymentStatuses } from '../../constants/PaymentStatuses.jsx';
import { Chip } from '@mui/material';
import { OrderStatuses } from '../../constants/OrderStatuses.jsx';
import ThumbsUpIcon from '../../icons/ThumbsUpIcon.jsx';
import FileXIcon from '../../icons/FileXIcon.jsx';
import ProhibitIcon from '../../icons/ProhibitIcon.jsx';
import EyeIcon from '../../icons/EyeIcon.jsx';
import TruckIcon from '../../icons/TruckIcon.jsx';
import SealCheckIcon from '../../icons/SealCheckIcon.jsx';
import WarningIcon from '../../icons/WarningIcon.jsx';
import { PaymentTermTypes } from '../../constants/PaymentTermTypes.jsx';
import { useTranslation } from 'react-i18next';
import { addDays, endOfDay } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getChipColor } from '../../routes/retail/orders/orderUtil.js';

export const getChipIcon = (status) => {
    const icons = {
        [OrderStatuses.APPROVED]: ThumbsUpIcon,
        [OrderStatuses.CANCELLED]: FileXIcon,
        [OrderStatuses.DECLINED]: ProhibitIcon,
        [OrderStatuses.IN_REVIEW]: EyeIcon,
        [OrderStatuses.SHIPPED]: TruckIcon,
        [OrderStatuses.PAID]: SealCheckIcon,
        [PaymentStatuses.COMPLETE]: SealCheckIcon,
        [PaymentStatuses.FAILED]: WarningIcon,
    };
    return icons[status] || null;
};

export default function OrderStatusChip({ order }) {
    const { t } = useTranslation();
    const flags = useFlags();
    // TODO: lastPayment may not be the completed payment
    const lastPayment = [...(order.payments || [])].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).pop();
    const hasCompletedPayment = lastPayment?.status === PaymentStatuses.COMPLETE;
    const hasFailedPayment = lastPayment?.status === PaymentStatuses.FAILED;

    let status = order.status;
    if (hasCompletedPayment && order.status === OrderStatuses.SHIPPED) {
        status = lastPayment?.status;
    }
    let chipColor = getChipColor(status);
    let ChipIcon = getChipIcon(status);

    let label = status;
    if (flags?.paymentTerms && order.status === OrderStatuses.SHIPPED && order.shippedOn) {
        const netDays = order?.paymentTermData?.netDays || 0;
        const dueDate =
            order?.paymentTermData?.type === PaymentTermTypes.NET_DAYS || !order?.paymentTermId
                ? endOfDay(addDays(new Date(order?.shippedOn), netDays))
                : null;
        const today = endOfDay(new Date());
        if (dueDate && today >= dueDate && !hasCompletedPayment) {
            label = t('OrderStatusChip.paymentDueLabel');
            chipColor = 'destructive';
            ChipIcon = WarningIcon;
        }
    }
    if (hasFailedPayment) {
        label = lastPayment?.status;
    }

    return (
        <Chip
            icon={ChipIcon ? <ChipIcon className="MuiIcon-root" sx={{ width: '16px', height: '16px' }} /> : undefined}
            variant="orderStatus"
            color={chipColor}
            label={label}
            sx={{ minWidth: 100 }}
        />
    );
}

OrderStatusChip.propTypes = {
    order: PropTypes.object.isRequired,
};

import { useMediaQuery, useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { some } from 'lodash';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigation, useParams, useRouteLoaderData, useSubmit } from 'react-router-dom';
import * as api from '../../../api';
import { CartProvider } from '../../../cart/CartContext';
import { selectItems, selectSubtotalValue, useCart } from '../../../cart/useCart';
import Crumbs from '../../../components/Crumbs/Crumbs';
import KeyboardPlaceholder from '../../../components/KeyboardPlaceholder/KeyboardPlaceholder.jsx';
import LoadingButton from '../../../components/LoadingButton/LoadingButton';
import SearchBar from '../../../components/SearchBar/SearchBar';
import SelectDealerDrawer from '../../../components/SelectDealerModal/SelectDealerDrawer.jsx';
import SelectDealerModal from '../../../components/SelectDealerModal/SelectDealerModal.jsx';
import ShipToCard from '../../../components/ShipToCard/ShipToCard.jsx';
import { OrderStatuses } from '../../../constants/OrderStatuses';
import OrderItemCard from '../../cart/OrderItemCard';
import OrderSummaryCard from '../../cart/OrderSummaryCard';
import { filterProducts } from '../../cart/cartUtil';
import EditLineItemsDialog from './BrandOrderDetails/EditLineItemsDialog';
import BrandProductVariantCard from './BrandProductVariantCard.jsx';
import RemoveOrderItemDialog from './RemoveOrderItemDialog';
import PageHeader from '../../../components/PageHeader/PageHeader.jsx';
import CartHeader from '../../cart/CartHeader.jsx';

export async function action({ request, params }) {
    const payload = await request.json();
    const newOrder = await api.createOrder(payload, { signal: request.signal });

    // TODO make utility for this?
    localStorage.removeItem(`self-order-cart-${params.cartId}`);

    // TODO allow for setting the status on create order when created by a brand
    await api.updateOrderStatus(
        newOrder.id,
        { businessId: newOrder.businessId, status: OrderStatuses.APPROVED },
        { signal: request.signal }
    );

    // request a payment link on behalf of the payee
    const response = await api.payForOrder(newOrder.id, { signal: request.signal });
    window.location = response?.url;
    return null;
}

export default function BrandCreateOrderDetails() {
    const { t } = useTranslation();
    const { business: initialBusiness, user } = useRouteLoaderData('root') || {};
    const submit = useSubmit();
    const navigation = useNavigation();
    const targetProductVariant = useRef(null);
    const [search, setSearch] = React.useState('');
    const [showManageOrderDetailsDialog, setShowManageOrderDetailsDialog] = React.useState(false);
    const [showRemoveItemDialog, setShowRemoveItemDialog] = React.useState(false);
    const [business, setBusiness] = useState(initialBusiness);
    const {
        state,
        state: { businessId } = {},
        removeItem,
        updateAddress,
        updateItem,
        updateOrderDetails,
        updatePaymentTerms,
        updateRetailBusiness,
    } = useCart();
    const isRetailBusinessAddressSelected = state.retailBusinessId && state.address;
    const [showSelectDealerModal, setShowSelectDealerModal] = useState(!isRetailBusinessAddressSelected);
    const theme = useTheme();
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const isLoading = navigation.state === 'loading';
    const isSubmitting = navigation.state === 'submitting';
    const isBusinessPayoutsEnabled = business?.settings?.paymentProviderSettings?.payoutsEnabled;

    const productVariants = selectItems(state);
    const filteredProductsVariants = filterProducts(productVariants, search);
    const selectedDealerInfo = {
        address: state.address,
        dealer: state.retailBusinessObject,
    };

    const handleDealerSelect = (dealerInfo) => {
        if (!some(business.connectedBusiness, { id: dealerInfo.dealer.id })) {
            setBusiness((prevBusiness) => ({
                ...prevBusiness,
                connectedBusiness: [...prevBusiness.connectedBusiness, dealerInfo.dealer],
            }));
        }
        // Update cart
        updateAddress(dealerInfo.address);
        updateRetailBusiness(dealerInfo.dealer);
        handleSetTermsFromBusinessConnectionSettings(dealerInfo.dealer);
    };

    const handleSetTermsFromBusinessConnectionSettings = async (dealer) => {
        const businessConnectionSettings = await api.getConnectionSettings({ connectedBusinessId: dealer.id });
        updatePaymentTerms(businessConnectionSettings.paymentTerm);
    };

    const onChangeDealer = () => {
        setShowSelectDealerModal(true);
    };

    const handleSubmit = () => {
        const {
            approvalNote,
            address,
            discountAmount,
            discountType,
            retailBusinessId,
            shippingCost,
            terms,
            paymentTerms,
        } = state;
        const payload = {
            addresses: [address].map((a) => ({
                label: a.label,
                name: a.name,
                street1: a.street1,
                street2: a.street2,
                street3: a.street3,
                city: a.city,
                state: a.state,
                postalCode: a.postalCode,
                countryCode: a.countryCode,
                emails: a.emails,
                phones: a.phones,
            })),
            approvalNote,
            businessId,
            createdByBusinessId: businessId,
            createdByUser: user?.user?.id,
            discountAmount,
            discountType,
            orderItems: productVariants.map((i) => ({
                quantity: parseInt(i.quantity),
                price: parseFloat(i.price),
                description: i.description,
                title: i.title,
                productVariantId: i.id,
            })),
            retailBusinessId,
            shippingCost,
            status: OrderStatuses.APPROVED,
            subTotal: selectSubtotalValue(state),
            paymentTermId: paymentTerms?.id,
            /** deprecated */
            terms,
        };
        // TODO add form validation
        submit(payload, { method: 'post', encType: 'application/json' });
    };

    const handleOpenManageDetailsDialog = () => {
        setShowManageOrderDetailsDialog(true);
    };
    const handleSendInvoiceClick = () => {};

    const handleUpdateOrderItem = (param) => {
        updateItem(param);
    };
    const handleOnRemoveClick = (e, id) => {
        setShowRemoveItemDialog(true);
        targetProductVariant.current = id;
    };
    const handleDeleteProduct = () => {
        removeItem(targetProductVariant.current);
        setShowRemoveItemDialog(false);
        targetProductVariant.current = null;
    };
    const handleCloseRemoveItemDialog = () => {
        setShowRemoveItemDialog(false);
    };
    const handleUpdateOrderDetails = (e, values) => {
        const { discountType, discountValue: discountAmount, shippingValue: shippingCost, paymentTermId } = values;
        const payload = {
            ...state,
            discountAmount,
            discountType,
            shippingCost,
        };
        updateOrderDetails(payload);
        setShowManageOrderDetailsDialog(false);
        handleSetTermsFromPaymentTermId(paymentTermId);
    };
    const handleSetTermsFromPaymentTermId = async (paymentTermId) => {
        const paymentTerm = await api.getPaymentTerm(paymentTermId);
        updatePaymentTerms(paymentTerm);
    };

    return (
        <>
            <PageHeader sx={{ height: { md: 48 }, mb: { md: 3 } }} />
            <Box sx={{ pb: 1 }}>
                <Crumbs />
            </Box>
            <Container maxWidth="xl">
                <Box sx={{ mb: 10, mx: { lg: 9 } }} data-testid="brand-create-order-details">
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row-reverse' },
                            flexWrap: { xs: 'wrap', md: 'nowrap' },
                            gap: { xs: 1, sm: 3, md: 6, lg: 18 },
                        }}>
                        {business && (
                            <Box sx={{ width: { xs: '100%', md: 'initial' } }}>
                                <OrderSummaryCard
                                    hideBusinessName={false}
                                    hideLogo={false}
                                    hideTitle={false}
                                    sx={{
                                        textAlign: 'center',
                                        width: { sm: 256 },
                                    }}
                                    state={state}
                                    variant="BRAND_SALES_REP"
                                    business={selectedDealerInfo?.dealer}
                                    OrderSummaryCardActions={
                                        <Stack gap={1}>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                onClick={handleOpenManageDetailsDialog}>
                                                {t('OrderSummaryCard.buttons.manageDetailsLabel')}
                                            </Button>
                                            <LoadingButton
                                                isLoading={isSubmitting || isLoading}
                                                disabled={
                                                    !isRetailBusinessAddressSelected ||
                                                    !isBusinessPayoutsEnabled ||
                                                    !productVariants.length ||
                                                    isSubmitting ||
                                                    isLoading
                                                }
                                                fullWidth
                                                variant="contained"
                                                size="small"
                                                color="success"
                                                name="intent"
                                                value="payNow"
                                                onClick={handleSubmit}>
                                                {t('OrderSummaryCard.buttons.takePaymentLabel')}
                                            </LoadingButton>
                                            {isBusinessPayoutsEnabled ? null : (
                                                <Alert severity="warning">
                                                    {t('OrderSummaryCard.alert.completeStripeToCompletePayment')}
                                                </Alert>
                                            )}
                                            <LoadingButton
                                                disabled={true}
                                                fullWidth
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                name="intent"
                                                value="sendInvoice"
                                                onClick={handleSendInvoiceClick}>
                                                {t('OrderSummaryCard.buttons.sendInvoiceLabel')}
                                            </LoadingButton>
                                        </Stack>
                                    }
                                />
                            </Box>
                        )}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, minWidth: 0, flex: '1 1 auto' }}>
                            <Box sx={{ my: 2 }}>
                                <ShipToCard dealerInfo={selectedDealerInfo} onClick={onChangeDealer} />
                            </Box>
                            <Box>
                                <SearchBar
                                    placeholder={t('Cart.searchMyCartLabel')}
                                    sx={{ maxWidth: 240, mb: 1 }}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <Divider />
                            </Box>
                            {filteredProductsVariants.map((product, i) =>
                                !isSmBreakpoint ? (
                                    <BrandProductVariantCard
                                        editable
                                        editablePrice
                                        key={product.id + `${i}`}
                                        onChange={handleUpdateOrderItem}
                                        onRemove={handleOnRemoveClick}
                                        variant={product}
                                    />
                                ) : (
                                    <OrderItemCard
                                        editable
                                        editablePrice
                                        key={product.id + `${i}`}
                                        product={product}
                                        onChange={handleUpdateOrderItem}
                                        onRemove={handleOnRemoveClick}
                                    />
                                )
                            )}
                            {!filteredProductsVariants.length && (
                                <Box>
                                    {search ? (
                                        <Typography>{t('Cart.noMatchingResultsLabel')}</Typography>
                                    ) : (
                                        <Typography>{t('Cart.emptyLabel')}</Typography>
                                    )}
                                </Box>
                            )}
                            {!isSmBreakpoint ? <KeyboardPlaceholder /> : null}
                        </Box>
                    </Box>
                </Box>
            </Container>
            <EditLineItemsDialog
                discountValue={state.discountAmount}
                discountType={state.discountType}
                shippingValue={state.shippingCost}
                termsValue={state.terms}
                paymentTermId={state.paymentTerms?.id}
                open={showManageOrderDetailsDialog}
                onConfirm={handleUpdateOrderDetails}
                onClose={() => setShowManageOrderDetailsDialog(false)}
            />
            <RemoveOrderItemDialog
                open={showRemoveItemDialog}
                onClose={handleCloseRemoveItemDialog}
                onConfirm={handleDeleteProduct}
            />
            {!isSmBreakpoint ? (
                <SelectDealerDrawer
                    open={showSelectDealerModal}
                    handleClose={() => setShowSelectDealerModal(false)}
                    onDealerSelect={handleDealerSelect}
                    dealerInfo={selectedDealerInfo}
                    businessConnections={business?.connectedBusiness ?? []}
                />
            ) : (
                <SelectDealerModal
                    open={showSelectDealerModal}
                    onClose={() => setShowSelectDealerModal(false)}
                    onDealerSelect={handleDealerSelect}
                    dealerInfo={selectedDealerInfo}
                    businessConnections={business?.connectedBusiness ?? []}
                />
            )}
        </>
    );
}

export function WithProvider() {
    const { cartId } = useParams();
    const { clearCart } = useCart();
    React.useEffect(() => {
        return () => {
            clearCart(`self-order-cart-${cartId}`);
        };
    }, []);
    return (
        <CartProvider storageKey={`self-order-cart-${cartId}`}>
            <CartHeader hideLogo={true} />
            <BrandCreateOrderDetails />
        </CartProvider>
    );
}

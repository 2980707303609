import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CircleMinusIcon, CirclePlusIcon } from 'lucide-react';
import CategorySelectableList from '../../../../components/CategorySelectableList/CategorySelectableList.jsx';
import { Tide } from '../../../../theme.js';
import PriceSelectableList from '../../../../components/PriceSelectableList/PriceSelectableList.jsx';

export default function ProductFacets(props) {
    const { t } = useTranslation();
    const [search, setSearch] = useSearchParams();

    const minPrice = search.get('minPrice');
    const maxPrice = search.get('maxPrice');
    const pricesSelectedValue = {
        min: minPrice ? Number(minPrice) : undefined,
        max: maxPrice ? Number(maxPrice) : undefined,
    };

    // Use a Set to track expanded accordions
    const [expandedAccordions, setExpandedAccordions] = useState(new Set(['categories', 'price']));

    const toggleAccordion = (accordion) => {
        setExpandedAccordions((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(accordion)) {
                newSet.delete(accordion);
            } else {
                newSet.add(accordion);
            }
            return newSet;
        });
    };

    const handleCategorySelected = (selectedCategoryIds) => {
        setSearch((prev) => {
            const updatedSearchParams = { ...Object.fromEntries(search) };

            if (selectedCategoryIds.length > 0) {
                updatedSearchParams.categoryIds = selectedCategoryIds.join(',');
            } else {
                delete updatedSearchParams.categoryIds;
            }

            return updatedSearchParams;
        });
    };

    const handlePriceSelected = (value) => {
        const minPrice = value.min;
        const maxPrice = value.max;

        // Only update if there's a change
        if (pricesSelectedValue.min === minPrice && pricesSelectedValue.max === maxPrice) {
            return;
        }

        setSearch((prev) => {
            const update = { ...Object.fromEntries(search) };

            if (minPrice === Infinity || minPrice === undefined) {
                delete update.minPrice;
            } else {
                update.minPrice = minPrice;
            }

            if (maxPrice === Infinity || maxPrice === undefined) {
                delete update.maxPrice;
            } else {
                update.maxPrice = maxPrice;
            }

            return update;
        });
    };

    const categoryIdsParam = search.get('categoryIds');
    const categoryIds = categoryIdsParam ? categoryIdsParam.split(',') : [];

    const filterTitleStyle = { fontWeight: 700, color: Tide };
    const accordionStyle = {
        border: 'none',
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
        '&.MuiAccordion-root': {
            border: 'none',
            boxShadow: 'none',
        },
    };

    return (
        <Stack gap={1} {...props}>
            <Typography variant="textXl" sx={{ ml: 2, fontWeight: 800 }}>
                {t('ConnectedBrandProducts.allFiltersLabel')}
            </Typography>
            <Box>
                <Accordion
                    sx={accordionStyle}
                    expanded={expandedAccordions.has('categories')}
                    onChange={() => toggleAccordion('categories')}>
                    <AccordionSummary
                        expandIcon={
                            expandedAccordions.has('categories') ? (
                                <CircleMinusIcon stroke={Tide} />
                            ) : (
                                <CirclePlusIcon stroke={Tide} />
                            )
                        }>
                        <Typography variant="textLg" sx={filterTitleStyle}>
                            {t('ConnectedBrandProducts.categoryLabel')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CategorySelectableList value={categoryIds} onChange={handleCategorySelected} />
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box>
                <Accordion
                    sx={accordionStyle}
                    expanded={expandedAccordions.has('price')}
                    onChange={() => toggleAccordion('price')}>
                    <AccordionSummary
                        expandIcon={
                            expandedAccordions.has('price') ? (
                                <CircleMinusIcon stroke={Tide} />
                            ) : (
                                <CirclePlusIcon stroke={Tide} />
                            )
                        }>
                        <Typography variant="textLg" sx={filterTitleStyle}>
                            {t('ConnectedBrandProducts.priceLabel')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceSelectableList value={pricesSelectedValue} onChange={handlePriceSelected} />
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Stack>
    );
}

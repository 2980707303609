import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { BorderRadius, BoxShadow, Teal, Tide, White } from '../../theme.js';
import PlusIcon from '../../icons/PlusIcon.jsx';
import { Button, Popover, List, Dialog, Grow } from '@mui/material';
import { useMatches, useNavigate, useRouteLoaderData } from 'react-router-dom';
import UserCirclePlusIcon from '../../icons/UserCirclePlusIcon.jsx';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import InviteBusiness from '../InviteBusiness/InviteBusiness.jsx';
import IconButton from '@mui/material/IconButton';
import CircleXIcon from '../../icons/CircleXIcon.jsx';
import { BusinessTypes } from '../../constants/BusinessTypes.jsx';
import { useTranslation } from 'react-i18next';
import ShoppingBagOpenIcon from '../../icons/ShoppingBagOpenIcon.jsx';
import PackageIcon from '../../icons/PackageIcon.jsx';

export default function QuickActionButton() {
    const matches = useMatches();
    const rootData = useRouteLoaderData('root');
    const business = rootData?.business;
    const [anchorEl, setAnchorEl] = useState(null);
    const [showInviteBusiness, setShowInviteBusiness] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const open = Boolean(anchorEl);
    const id = open ? 'quick-action-popover' : undefined;

    const handleAction = (event) => {
        setAnchorEl(open ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleInviteBusiness = () => {
        setShowInviteBusiness(true);
        handleClose();
    };

    const handleCreateProduct = () => {
        navigate('/brand/products/create-product');
    };

    const handleAddOrder = () => {
        navigate('/brand/new-order');
    };

    const renderPopoverContent = () => {
        // Add new actions based on routes here:
        if (
            matches.some(({ pathname }) => pathname.includes('/brand/connections')) ||
            matches.some(({ pathname }) => pathname.includes('/brand/orders')) ||
            matches.some(({ pathname }) => pathname.includes('/brand/products'))
        ) {
            return (
                <List>
                    <ListItemButton onClick={handleCreateProduct}>
                        <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                            <ShoppingBagOpenIcon sx={{ width: 24, height: 24, color: Teal }} />
                            <Typography variant="label">{t('QuickActionButton.createProduct')}</Typography>
                        </Stack>
                    </ListItemButton>
                    <ListItemButton onClick={handleAddOrder}>
                        <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                            <PackageIcon sx={{ width: 24, height: 24, color: Teal }} />
                            <Typography variant="label">{t('QuickActionButton.addAnOrder')}</Typography>
                        </Stack>
                    </ListItemButton>
                    <ListItemButton onClick={handleInviteBusiness}>
                        <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                            <UserCirclePlusIcon sx={{ width: 24, height: 24, color: Teal }} />
                            <Typography variant="label">
                                {business.type === BusinessTypes.RETAIL
                                    ? t('QuickActionButton.inviteBrand')
                                    : t('QuickActionButton.inviteDealer')}
                            </Typography>
                        </Stack>
                    </ListItemButton>
                </List>
            );
        }
    };

    return (
        <>
            <Button
                onClick={handleAction}
                sx={{
                    padding: 0,
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '64px',
                        width: '64px',
                        backgroundColor: Tide,
                        borderRadius: '100%',
                        boxShadow: BoxShadow,
                        transition: 'transform 0.3s ease-in-out',
                        transform: open ? 'rotate(45deg)' : 'rotate(0deg)',
                        cursor: 'pointer !important',
                    }}>
                    <PlusIcon sx={{ color: White }} />
                </Box>
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                TransitionComponent={Grow}
                TransitionProps={{
                    timeout: 300,
                }}
                slotProps={{
                    paper: {
                        sx: {
                            borderRadius: BorderRadius,
                            transition: 'transform 0.3s ease-in-out',
                        },
                    },
                }}
                sx={{
                    mt: -2,
                    pointerEvents: 'none',
                }}>
                <Box sx={{ pointerEvents: 'all' }}>{renderPopoverContent()}</Box>
            </Popover>
            <Dialog open={showInviteBusiness} onClose={() => setShowInviteBusiness(false)} fullWidth>
                <Box sx={{ width: '100%', maxWidth: 600, position: 'relative' }}>
                    <IconButton
                        onClick={() => setShowInviteBusiness(false)}
                        sx={{ position: 'absolute', top: 8, right: 8 }}>
                        <CircleXIcon />
                    </IconButton>
                    <InviteBusiness
                        onSuccess={() => {
                            setTimeout(() => {
                                setShowInviteBusiness(false);
                            }, 1000);
                        }}
                    />
                </Box>
            </Dialog>
        </>
    );
}

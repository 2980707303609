import React, { useState, useEffect } from 'react';
import { FormControl, FormControlLabel, Checkbox } from '@mui/material';
import { useLoaderData } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function CategorySelectableList(props) {
    const { value = [], onChange } = props;
    const { availableCategories: categories } = useLoaderData();
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        if (onChange) {
            onChange(localValue);
        }
    }, [localValue]);

    const handleCheckboxChange = (categoryId) => {
        const newValue = localValue.includes(categoryId)
            ? localValue.filter((id) => id !== categoryId)
            : [...localValue, categoryId];

        setLocalValue(newValue);
    };

    return (
        <FormControl component="fieldset" data-testid="category-selectable-list">
            {categories?.map((category) => (
                <FormControlLabel
                    key={category?.id}
                    control={
                        <Checkbox
                            checked={localValue.includes(category?.id)}
                            onChange={() => handleCheckboxChange(category?.id)}
                        />
                    }
                    label={category?.name}
                />
            ))}
        </FormControl>
    );
}

CategorySelectableList.propTypes = {
    categories: PropTypes.array,
    onChange: PropTypes.func,
    defaultValue: PropTypes.number,
    value: PropTypes.array,
};

import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import WholeshopLogo from '../icons/WholeshopLogo.jsx';
import PropTypes from 'prop-types';
import { Teal } from '../theme.js';

export default function PoweredByThatch({ color }) {
    const { t } = useTranslation();
    return (
        <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
            <Typography sx={{ fontSize: '14px', color: color || Teal, fontWeight: 500, lineHeight: 'normal' }}>
                {t('PoweredByThatch.poweredByLabel')}
            </Typography>
            <WholeshopLogo sx={{ width: 115 }} />
        </Stack>
    );
}

PoweredByThatch.propTypes = {
    color: PropTypes.any,
};

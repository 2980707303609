import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SearchBar from '../SearchBar/SearchBar.jsx';
import List from '@mui/material/List';
import DealerListItem from './DealerListItem.jsx';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import AddAddressForm from '../AddressInput/AddAddressForm.jsx';
import { Dialog, DialogContent } from '@mui/material';
import { AddressInputType } from '../../constants/AddressInputType.jsx';
import * as api from '../../api.js';
import { useError } from '../Error/ErrorProvider.jsx';
import { getAuthToken } from '../../providers/auth.js';

export default function SelectDealerModal(props) {
    const { open, onClose, dealerInfo, onDealerSelect, businessConnections } = props;
    const { t } = useTranslation();
    const [dealers, setDealers] = useState(businessConnections);
    const [selectedDealer, setSelectedDealer] = useState(dealerInfo?.dealer);
    const [selectedAddress, setSelectedAddress] = useState(dealerInfo?.address);
    const [createNewDealerOpen, setCreateNewDealerOpen] = useState(false);
    const [isCreatingNewDealer, setIsCreatingNewDealer] = useState(false);
    const { handleError } = useError();

    const style = {
        position: 'absolute',
        outline: 'none',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '90%' },
        maxWidth: 700,
        height: 550,
        backgroundColor: 'background.paper',
        boxShadow: 24,
        borderRadius: '6px',
    };
    const handleDealerSelect = (dealer) => {
        setSelectedDealer(dealer.id);
        const primaryAddress = dealer.addresses.find((address) => address.label === 'Primary') || dealer.addresses[0];
        handleAddressSelect(primaryAddress);
    };
    const handleAddressSelect = (address) => {
        setSelectedAddress(address);
    };
    const handleUseAddressClick = (e) => {
        if (onDealerSelect) {
            onDealerSelect({ dealer: _.find(dealers, { id: selectedDealer }), address: selectedAddress });
        }
        if (onClose) {
            onClose(e);
        }
    };
    const onDidCreateNewDealer = async (dealer) => {
        // Force token refresh to assure all business links in token
        await getAuthToken(true);
        setCreateNewDealerOpen(false);
        handleDealerSelect(dealer);
        onDealerSelect({ dealer, address: dealer.addresses[0] });
    };
    const onCreateNewDealer = async (newDealerData) => {
        setIsCreatingNewDealer(true);
        try {
            const user = {
                username: newDealerData.email.toLowerCase(),
                firstName: newDealerData.firstName,
                lastName: newDealerData.lastName,
            };
            const business = {
                type: 'Retail',
                name: newDealerData.name,
                addresses: [
                    {
                        name: newDealerData.name,
                        label: 'Primary',
                        street1: newDealerData.street1,
                        street2: newDealerData.street2,
                        street3: '',
                        city: newDealerData.city,
                        state: newDealerData.state,
                        postalCode: newDealerData.postalCode,
                        countryCode: newDealerData.countryCode,
                        phones: [
                            {
                                primary: newDealerData.phoneNumber.replace(/\s/g, ''),
                            },
                        ],
                        emails: [
                            {
                                default: newDealerData.email,
                            },
                        ],
                    },
                ],
            };
            const createdDealer = await api.createRetailer({ user, business }, {});
            await onDidCreateNewDealer(createdDealer);
        } catch (error) {
            handleError(error);
        } finally {
            setIsCreatingNewDealer(false);
        }
    };

    const handleSearch = (event) => {
        const query = event.target.value;
        if (_.isEmpty(query)) {
            return setDealers(businessConnections);
        } else {
            const filteredDealers = businessConnections.filter((dealer) => {
                return dealer.name.includes(query);
            });
            setDealers(filteredDealers);
        }
    };

    useEffect(() => {
        if (open) {
            setDealers(businessConnections);
        }
    }, [open]);

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={style}>
                    <Stack sx={{ pl: 4, pr: 4, pt: 3 }}>
                        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                            <Typography sx={{ fontWeight: 700, fontSize: '20px' }}>
                                {t('SelectDealerModal.chooseDealerLabel')}
                            </Typography>
                            <Button onClick={() => setCreateNewDealerOpen(true)}>
                                {t('SelectDealerModal.enterAddressButton')}
                            </Button>
                        </Stack>
                        <Stack direction="row" gap={1} sx={{ marginBottom: 2 }}>
                            <SearchBar sx={{ width: '35%', height: '27px' }} onChange={handleSearch} />
                            <Typography sx={{ fontSize: '14px' }}>
                                {'Showing ' + dealers?.length + ' Dealers'}
                            </Typography>
                        </Stack>
                        <Box sx={{ flexGrow: 1 }}>
                            <List
                                sx={{
                                    height: 365,
                                    overflowY: 'auto',
                                    '::-webkit-scrollbar': {
                                        width: '8px',
                                    },
                                    '::-webkit-scrollbar-thumb': {
                                        background: '#1DD75BFF',
                                        borderRadius: '4px',
                                    },
                                    '::-webkit-scrollbar-thumb:hover': {
                                        background: '#1AA64CFF',
                                    },
                                }}>
                                {dealers?.map((dealer) => (
                                    <DealerListItem
                                        key={dealer.id}
                                        dealerInfo={
                                            dealer.id === dealerInfo?.dealer?.id
                                                ? dealerInfo
                                                : { dealer, address: null }
                                        }
                                        isSelected={selectedDealer === dealer.id}
                                        onAddressSelect={handleAddressSelect}
                                        onClick={() => handleDealerSelect(dealer)}
                                    />
                                ))}
                                {(!dealers || !dealers.length) && (
                                    <Box sx={{ mx: 5, my: 4, textAlign: 'center' }}>
                                        <Typography variant="subtitle1">{t('Connections.emptyStateLabel')}</Typography>
                                    </Box>
                                )}
                            </List>
                        </Box>
                    </Stack>
                    <Divider variant="fullWidth" sx={{ width: '100%' }} />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 2,
                        }}>
                        <Button
                            variant="contained"
                            disabled={!selectedAddress}
                            onClick={handleUseAddressClick}
                            sx={{
                                height: '44px',
                                textTransform: 'none',
                                fontSize: '16px',
                                fontWeight: 400,
                                width: 172,
                            }}>
                            Use Address
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Dialog
                open={createNewDealerOpen}
                PaperProps={{
                    sx: {
                        maxWidth: 'none',
                        maxHeight: '80vh',
                    },
                }}
                onClose={() => setCreateNewDealerOpen(false)}>
                <DialogContent>
                    <Box
                        sx={{
                            overflowY: 'auto',
                            borderRadius: '6px',
                        }}>
                        <AddAddressForm
                            type={AddressInputType.DEALER}
                            isCheckout={true}
                            isLoading={isCreatingNewDealer}
                            onCancel={() => setCreateNewDealerOpen(false)}
                            onConfirm={onCreateNewDealer}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

SelectDealerModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    dealerInfo: PropTypes.object,
    addressIndex: PropTypes.number,
    onDealerSelect: PropTypes.func,
    businessConnections: PropTypes.array,
};
